import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetAll()
{
    let response = await GET(CompanyRoutes.SubCategories.GetAll);
    return response;
}


export async function AddCategory(nombre,descripcion,estatus,imagen)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen}
    ];

    let response = await POST(CompanyRoutes.SubCategories.Add,params);
    return response;

}

export async function UpdateCategory(uuid,nombre,descripcion,estatus,imagen)
{
    const params = [
        {name:'uuid',value:uuid},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen}
    ];

    let response = await POST(CompanyRoutes.SubCategories.Update,params);
    return response;
}