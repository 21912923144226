import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import WebsiteAppBar from "./WebsiteAppBar";
import Footer from "../../views/website/footer";
import { Box, CssBaseline } from "@mui/material";
import Copyright from "../../components/copyRight";

import DownloadApp from '../../components/downloadApp'

export function WebSiteLayout2(props)
{
    useEffect(()=>{
        window.scrollTo(0,0);
    })



    return(
        <Box sx={{bgcolor:'#FFF' }}>
          	<CssBaseline />
            <WebsiteAppBar/>
            <Box sx={{pt:{xs:12,lg:13,xl:13},minHeight:'90vh'}}>
                {props.children}
            </Box>
            <Footer/>
        </Box>
        
    )
}