import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST } from "../ConsumeApiController";



export async function get_categories(branch_id)
{
    let response = await GET(AdminRoutes.Categories.GetAll+branch_id);
    return response;
}


export async function create_category(branch_id,name,description)
{
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'name',value:name},
        {name:'description',value:description},
    ];

    let response = await POST(AdminRoutes.Categories.Create,params);
    return response;
}


export async function show_category(category_id)
{
    let response = await GET(AdminRoutes.Categories.Show+category_id);
    return response;
}


export async function update_category(category_id,name,description)
{
    const params = [
        {name:'category_id',value:category_id},
        {name:'name',value:name},
        {name:'description',value:description},
    ];

    let response = await POST(AdminRoutes.Categories.Update,params);
    return response;
}

