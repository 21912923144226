


/**Production */
const server = 'https://ffapi.qud.life/';
const domain = 'https://ffapi.qud.life/';
const stripePromisePK = 'pk_live_Oys6bNQymyw8ELnivfqapcV800tOhFdENJ';
//Aqui terminan las url de prod */

/**Development *
const server = 'http://192.168.1.104:8000/';
const domain = 'http://192.168.1.104:3000/';
//const stripePromisePK = 'pk_test_tXggoq3rcB1QHynt7CZ3ZVg400NUsXP6Ue';
//Aqui terminan las url de dev */




const api = 'api/v1/';

const imagesURL = server+'api/public/storage/img/';

const url = server + api;

export {url,imagesURL,domain}

