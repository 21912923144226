import { useContext, useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getEntitiesByType } from "../../../controllers/admin/EntitiesController";
import EntitiesDataTable from "./dataTable";

export default function Customers()
{
    const { selectedBranch } = useContext(BranchContext);
    const { type } = useParams();

    const [isLoading,setIsLoading] = useState(false);
    const [entities,setEntities] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        handleGetEntities();
    },[]);

    const redirect = (route) => {
        history.push(route)
    }

    const handleGetEntities = async () => {
        setIsLoading(true);
        let response = await getEntitiesByType(selectedBranch.id,type);
        if(response.success === true)
        {
            setEntities(response.data);
        }
        setIsLoading(false);
    }

    const handleType = () => {
        if(type === 'customer')
            return 'Clientes';

        else if(type === 'supplier')
            return 'Proveedores';

        else
            return 'Empleados'
    };

    return(
        <Container>
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Entidades / ${handleType()}`}]}/>
            <SectionTitle title={handleType()}/>
            <Button
                variant='contained'
                endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                onClick={()=>redirect(`/entities/${type}/create`)}
            >
                <Typography>Agregar</Typography>
            </Button>
            <Grid container spacing={3} mt={2}>
                {
                    entities.length !== 0 &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <EntitiesDataTable entities={entities}/>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}