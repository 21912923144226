import React from 'react';
import { Breadcrumbs, IconButton, Link } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CustomBreadcrumbs(props)
{

    let history = useHistory();
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    return(
        <div style={{display:'flex',flexDirection:'row-reverse',justifyContent:'space-between'}}>
            <Breadcrumbs style={{marginTop:10}} aria-label="breadcrumb">
                
                <Link underline="hover" sx={{cursor:'pointer'}} color="inherit" onClick={()=>redireccionar('/')}>
                    Inicio
                </Link>
                {
                    props.crumbs &&
                    props.crumbs.map((item)=>
                        <Link
                            underline="hover"
                            color="inherit"
                            sx={{cursor:'pointer'}}
                            onClick={()=>redireccionar(item.link)}
                            aria-current="page"
                        >
                            {item.name}
                        </Link>
                    )
                }
            </Breadcrumbs>
            {
                props.returnTo &&
                <IconButton onClick={()=>history.push(props.returnTo)}>
                    <ArrowBackIcon/>
                </IconButton>
            }
            
        </div>
    )
}