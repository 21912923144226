import { Redirect, Route, Switch } from "react-router-dom";
import CompanyLayout from "../layouts/dashboardEmpresa/companyLayout";
import ChangePassword from "../views/auth/changePassword";
import CompanyHome from "../views/company";
import Categories from "../views/company/categories";
import CategoriesAdd from "../views/company/categories/add";
import CategoriesUpdate from "../views/company/categories/update";

import Products from "../views/company/products";
import ProductAdd from "../views/company/products/add";
import ProductUpdate from "../views/company/products/update";
import CompanyProfile from "../views/company/profile";


import AppRoute from "./AppRoute";
import Wallet from "../views/company/wallet";
import Pay from "../views/company/wallet/pay";
import DepositMethods from "../views/company/wallet/deposit/methods";

import MyBankAccounts from "../views/company/finances/banks";
import AddBankAccount from "../views/company/finances/banks/add";
import Orders from "../views/company/orders";

import OrderDetails from "../views/company/orders/details";



export default function CompanyNavigation()
{
    return(
        <Switch>
            <AppRoute path='/' layout={CompanyLayout} component={CompanyHome} />

            <AppRoute exact path='/profile' layout={CompanyLayout} component={CompanyProfile} />

            <AppRoute exact path='/security/changepassword' layout={CompanyLayout} component={ChangePassword} />

            <AppRoute exact path='/categories' layout={CompanyLayout} component={Categories} />
            <AppRoute exact path='/categories/add' layout={CompanyLayout} component={CategoriesAdd} />
            <AppRoute exact path='/categories/update' layout={CompanyLayout} component={CategoriesUpdate} />

            <AppRoute exact path='/products' layout={CompanyLayout} component={Products} />
            <AppRoute exact path='/products/add' layout={CompanyLayout} component={ProductAdd} />
            <AppRoute exact path='/products/update' layout={CompanyLayout} component={ProductUpdate} />

            <AppRoute exact path='/orders' layout={CompanyLayout} component={Orders} />
            <AppRoute exact path='/orders/details/:code' layout={CompanyLayout} component={OrderDetails} />

            
            

            <AppRoute exact path='/finances/banks' layout={CompanyLayout} component={MyBankAccounts} />
            <AppRoute exact path='/finances/banks/add' layout={CompanyLayout} component={AddBankAccount} />

            <AppRoute exact path='/wallet' layout={CompanyLayout} component={Wallet} />
            <AppRoute exact path='/wallet/pay' layout={CompanyLayout} component={Pay} />
            <AppRoute exact path='/wallet/deposit/methods' layout={CompanyLayout} component={DepositMethods} />

            
            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect path="/"/>
            </Route>
        </Switch>
    )
}