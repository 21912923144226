import { useContext, useEffect, useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import { GetAppUser } from "../../../controllers/AppsUsersController";
import { morocotas_getUser, morocotas_getUserTransactions } from "../../../controllers/MorocotasController";
import UserContext from "../../../navigation/context/userContext";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faBank, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import TransactionsDataTable from "./transactionsDatatable";
import { useHistory } from "react-router-dom";

import Lottie from "lottie-react";
import buildingAnimation from '../../../assets/lottie/building.json';

export default function Wallet()
{
    const { userData } = useContext(UserContext)
    const [app,setApp] = useState([]);
    const [balance,setBalance] = useState([]);
    const [transactions,setTransactions] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(()=>{
        handleGetAppUser();
    },[]);

    const redirect = (route) => {
        history.push(route);
    }

    const handleGetAppUser = async () => {
        setIsLoading(true);
        let response = await GetAppUser(1);
        console.log('response del token ===> ',response);
        if(response.success === true)
        {
            setApp(response.data);
            let morocotas_response = await morocotas_getUserTransactions(2,100,response.data.token);
            setBalance(morocotas_response.data.balance[0]);
            setTransactions(morocotas_response.data.transactions)
        }
    }


    return(
        <Container>
            <CustomBreadcrumbs returnTo='/' crumbs={[{name:'Billetera',link:'/wallet'}]}/>
            <br/>
            <SectionTitle title='Billetera' />
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                    <div>
                        <Lottie
                            autoPlay
                            style={{
                                width: 400,
                                height: 400,
                            }}
                            loop={false}
                            animationData={buildingAnimation}
                            
                        />
                        <Typography textAlign={'center'} variant="h5">Actualmente algunos modulos se encuentran en desarrollo</Typography>
                    </div>
                </Grid>
                {/**
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Paper elevation ={4}>
                        <Typography style={{fontWeight:'bold'}}>Saldo de QUD</Typography>
                        <Typography style={{marginTop:20, fontSize:35}}>
                            $ {balance.total_amount}
                        </Typography>
                        <Typography style={{fontSize:13}} color='grey'>Disponible</Typography>
                    </Paper>
                </Grid>

                {/**Mis Bancos *}
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Paper elevation ={4}>
                        <Typography style={{fontWeight:'bold'}}>Mis Bancos</Typography>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:20,marginBottom:20}}>
                            <FontAwesomeIcon style={{fontSize:50}} icon={faBank}/>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Paper elevation ={4}>
                        <Typography style={{fontWeight:'bold'}}>Retirar</Typography>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:20,marginBottom:20}}>
                            <FontAwesomeIcon style={{fontSize:50}} icon={faExternalLinkAlt}/>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Paper elevation ={4} onClick={()=>redirect('/wallet/deposit/methods')}>
                        <Typography style={{fontWeight:'bold'}}>Depositar</Typography>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:20,marginBottom:20}}>
                            <FontAwesomeIcon style={{fontSize:50}} icon={faArrowUp}/>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Paper elevation ={4} onClick={()=>redirect('/wallet/pay')}>
                        <Typography style={{fontWeight:'bold'}}>Pagar</Typography>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:20,marginBottom:20}}>
                            <FontAwesomeIcon style={{fontSize:50}} icon={faArrowDown}/>
                        </div>
                    </Paper>
                </Grid>

                */}
            </Grid>

            <br/><br/><br/>
            {/**Transacciones 
            <TransactionsDataTable transactions={transactions}/> */}
        </Container>
    )
}