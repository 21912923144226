import { url } from "./apiBase";

const WebsiteRoutes = {
    SmartCard:
    {
        GetProfile: url + 'public/smartcard/', //completar con el uuid del usuario {uuid}
    },
    Merchants:
    {
        GetCategories : url + 'categorias/all',
        GetMerchants: url + 'categorias/all/', //completar con Id de la categoria seleccionada
        GetMerchant: url + 'merchant/', //completar con el uuid del merchant
        GetMerchantProfile: url + 'public/smartcard',
        GetMerchantCategories: url + 'categorias/all/shop/', //completar con uuid del merchant
        GetMerchantProducts: url + 'categorias/all/shop/', //completar con el uuid del merchant y la subcategoria
        GetToSearchMerchants: url + 'tosearch/merchants',
        GetMerchantsByCategoryAndLocation: url + 'search/merchants',
        GetPaymentMethods: url + 'public/finanzas/byuuid/', //completar con el uuid de la empresa
    },
    Ciudades:
    {
        GetAll: url + 'configuracion/ciudades/all',
    },
    Eventos:
    {
        GetAll: url + 'public/events',
        GetEventByID: url + 'public/events/show/', //completar con el ID o el UUID del evento
        Categorias:
        {
            
            GetAll: url + 'eventos/categorias'
        },
        Tickets:
        {
            //Buy: url + 'comercios/pedido/boleto'
            Buy: url + 'public/events/buytickets'
        },
        Gastromedia:
        {
            GetEvento: url + 'comercios/evento/gastromedia/show/', //completar con el uuid del producto_evento
        },
        Sponsors:
        {
            getAllbyEventiID: url + 'public/events/sponsors/', //completar con el id del evento
        }
    },
    Stripe:{
        createPaymentIntent: url + 'stripe/payment/intent',
    },
    Reviews:
    {
        Create: url + 'reviews/create',
    },
    Faqs:
    {
        ContactUsForm: url + 'contactUsForm'
    }
}

export default WebsiteRoutes;

/*
CATEGORIAS : {
        GetAll : url + 'categorias/all',
        GetShops : url + 'categorias/all/',
        GetShopCategories : url + 'categorias/all/shop/',
        GetShopUser : url + 'categorias/all/shop/user/',    //completar con {idUsuario}
        GetShopProducts : url + 'categorias/all/shop/user/' //completar con {idUsuario}/{idSubCategoria}
    },
*/