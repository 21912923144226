import { Container, Grid, makeStyles, Typography } from "@mui/material";

export default function TermsAndConditions()
{
    

    const styles ={
        Subtitle: {
            fontWeight:'bold',
            lineHeight:1.8,
            letterSpacing:0.7,
            color:'#212121',
            
        },
        Text:{
            lineHeight:1.8,
            letterSpacing:0.7,
            color:'#212121',
            textAlign:'justify'
        },
        highlighted : {
            color:'#212121',
            fontWeight:'bold'
        },
    };

    return(
        <Container maxWidth='lg' sx={{mt:20,mb:10}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4" component="h2" style={{color:'#212121'}} gutterBottom>
                        Términos y Condiciones de Uso de QUD
                    </Typography>
                    <Typography variant='body1' gutterBottom style={styles.Text}>
                        El presente documento ha de describir los Términos y Condiciones Generales de 
                        Uso e información legal (en adelante, Términos, Condiciones Generales, Condiciones de Uso,
                        Términos y Condiciones) que se aplican al sitio web y app móvil de QUD, cuyo dominio es qud.life, 
                        (en adelante, el "sitio", "QUD", la "app", la "plataforma", el "sistema" ) incluyendo la contratación 
                        de los servicios administrados u ofrecidos a través del mismo (en adelante, el servicio, los servicios, nuestros servicios). 
                        así como a todos sus sitios relacionados o vinculados desde el sitio y a las correspondientes aplicaciones móviles.
                    </Typography>
                    <br/>

                    <Typography variant='subtitle1' paragraph style={styles.Text}>
                        El usuario del Sitio y el usuario registrado (en adelante, el usuario, los Usuarios), deberán declarar 
                        tener capacidad jurídica para contratar o representar a quien la tenga, así como haber leído, y estar 
                        de acuerdo con los presentes Términos y Condiciones, de igual manera de tratarse de un usuario como 
                        empresa o persona jurídica, deberá tener capacidad para poder representarla y contratar en su nombre. 
                        De no estar de acuerdo con los presentes Términos y Condiciones, el usuario deberá abstenerse de utilizar 
                        el presente sitio, así como los servicios ofrecidos en el mismo; por tanto, es de entenderse; que, al 
                        continuar la navegación por el sitio, se dará por entendió que usted ha aceptado y está de acuerdo con los 
                        Términos y Condiciones expresados, de no ser así y usted continúe la navegación por el sitio, lo hará bajo 
                        su propia responsabilidad. 
                    </Typography>

                    <Typography variant='subtitle1' paragraph style={styles.Text}>
                        La plataforma es propiedad de <span style={styles.highlighted}>TECHNOLOGICAL SHARKS C.A. J-40154882-2</span>.  
                    </Typography>

                    <br/>
                    <br/>
                    {/** OBJETO */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        Objeto
                    </Typography>

                    <Typography variant='subtitle1' paragraph style={styles.Text}>
                        QUD es una plataforma cuya actividad principal es ofrecer un Marketplace y permitir a las tiendas locales,
                        que cumplan con los Términos y Condiciones, ofertar sus productos a través de la misma, y en caso, que los 
                        usuarios así lo soliciten a través de la APP, de forma accesoria, intermedia en la entrega inmediata de los 
                        productos y la resolución de servicios. QUD cuenta con una Plataforma mediante la cual diferentes comercios,
                        puede mantener un acuerdo comercial por el uso de la plataforma, ofrecen una serie de productos y servicios. 
                        El Cliente tiene la posibilidad de solicitar la adquisición de productos y servicios de estos comercios 
                        mediante el mandato que confiere a un tercero al solicitar un pedido a través de la Plataforma, en cuyos 
                        casos QUD actúa como mero intermediario y, por lo tanto, no puede asumir ni asume responsabilidad alguna 
                        sobre la calidad de los productos o la correcta prestación de los servicios ofrecidos directamente por los 
                        comercios ni por dichos terceros. Asimismo, es una Plataforma de intermediación que pretende facilitar que 
                        aquellas personas que necesiten ayuda con sus encomiendas o sus compras en comercios asociados, puedan 
                        realizar sus gestiones mediante dichos terceros.
                    </Typography>


                    <Typography paragraph style={styles.Text}>
                        Los Agentes QUD, son una red de mensajeros/repartidores que trabajan con QUD, cuando éstos están interesados en 
                        realizar la prestación de servicios de mensajería, se conectan a la plataforma y en un tiempo determinado se 
                        comprometen a realizar el servicio de mensajería/reparto/entrega que le encomiende el Cliente. 
                    </Typography>


                    <Typography paragraph style={styles.Text}>
                        
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        
                    </Typography>

                    <br/>
                    {/** 1 - ACCESO Y REGISTRO PARA CLIENTES.   */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        1.ACCESO Y REGISTRO PARA CLIENTES.  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Para poder ser Cliente de la Plataforma es indispensable:
                    </Typography>

                    <div style={{marginLeft:20}}>
                        <Typography paragraph style={styles.Text}>
                            • Haber cumplido o ser mayor de 18 años de edad. 
                        </Typography>
                        <Typography paragraph style={styles.Text}>
                            • Completar de manera veraz los campos obligatorios del formulario de registro, 
                            en el que se solicitan datos de carácter personal como nombre de usuario, correo electrónico, 
                            número de teléfono y documento de identidad (cedula, licencia, pasaporte).  
                        </Typography>
                        <Typography paragraph style={styles.Text}>
                            • Aceptar las presentes Condiciones de uso, la Política de Privacidad y Protección de Datos. 
                        </Typography>

                        <Typography paragraph style={styles.Text, {color:'#ce000e'}} >
                            • En caso que el usuario que se registra sea menor de edad, se sobre entiende que tiene la autorización
                            de sus padres o representantes y que QUD y a TECHNOLOGICAL SHARKS CA quedan exonerados de toda responsabilidad
                            por daños y perjuicios que pueda sufrir el usuario menor de edad.   
                        </Typography>
                    </div>

                    <Typography paragraph style={styles.Text}>
                        En el uso que usted haga de la plataforma de QUD y/o de los servicios puestos a su disposición 
                        en o a través de la plataforma, se le puede pedir que nos proporcione cierta información personalizada. 
                        Las políticas de uso y recopilación de información de QUD con respecto a la privacidad de dicha 
                        Información del Usuario se establecen en la Política de Privacidad de QUD, la cual está incorporada 
                        en el sitio web. Usted reconoce y acepta ser el único responsable de la exactitud del contenido de la 
                        Información del Usuario. 
                    </Typography>


                    <br/><br/>
                    {/** 2 - CONDUCTA PROHIBIDA DEL USUARIO Y ALIADOS  */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        2.CONDUCTA PROHIBIDA DEL USUARIO Y ALIADOS.  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Usted garantiza y está de acuerdo en que, mientras use la plataforma de QUD, usted no: 
                    </Typography>

                    <div style={{marginLeft:20}}>
                            
                        <Typography paragraph style={styles.Text}>
                            • personalizará a ninguna persona o entidad ni desvirtuará su afiliación con alguna otra persona o entidad; 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • insertará su propio anuncio, posicionamiento de marca o algún otro contenido promocional o el de un 
                            tercero en cualquiera de los contenidos, materiales o servicios de la plataforma, ni usará, redistribuirá,
                            republicará o explotará dichos contenidos o servicios con cualquier otro propósito adicional comercial o 
                            promocional;
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • intentará ganar acceso no autorizado a otros sistemas de cómputo a través de la plataforma
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • participará en navegar por la red, en scraping de la pantalla, scraping de la base de datos, 
                            en recolectar direcciones de correo electrónico, direcciones inalámbricas u otra información 
                            personal o de contactos, o cualquier otro medio automático de obtener listas de usuarios u otra 
                            información de los servicios ofrecidos en o a través de la plataforma, incluyendo, sin limitación, 
                            cualquier información que se encuentre en algún servidor o base de datos relacionada con la plataforma 
                            o los servicios ofrecidos;
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • obtendrá o intentará obtener acceso no autorizado a los sistemas de cómputo, materiales o información 
                            por cualquier medio;
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • usará la plataforma de QUD o los servicios puestos a su disposición de alguna manera con la intención 
                            de interrumpir, dañar, deshabilitar, sobrecargar o deteriorar el Sitio Web o dichos servicios, incluyendo, 
                            sin limitación, mandar mensajes masivos no solicitados o “inundar” servidores con solicitudes;
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • usará la plataforma de QUD o los servicios en violación de la propiedad intelectual o de otros derechos 
                            legales o patrimoniales de QUD o de algún tercero aliados;
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • usará la plataforma o los servicios en violación de cualquier ley aplicable.
                        </Typography>
                    </div>

                    <Typography paragraph style={styles.Text}>
                        Usted se obliga, además, a no intentar (o alentar o apoyar el intento de otro) a embaucar, 
                        destruir, decodificar, o de otro modo alterar o interferir con la plataforma, con los servicios 
                        o con cualquier contenido del mismo, o hacer cualquier uso no autorizado del mismo. Usted se obliga
                        a no usar la plataforma de QUD de alguna manera que pudiera dañar, deshabilitar, sobrecargar o 
                        deteriorar la plataforma o interferir con que cualquier otra persona pueda usar o disfrutar de la 
                        plataforma o de cualquiera de sus servicios. 
                    </Typography>


                    
                    <br/><br/>
                    {/** 3 - ÓRDENES DE PRODUCTOS Y SERVICIOS.   */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        3.ÓRDENES DE PRODUCTOS Y SERVICIOS.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Ofrecemos productos y servicios a disposición de visitantes y registrados de la plataforma de QUD. Cuando un 
                        Usuario registrado como Cliente necesite ayuda con una gestión, deberá dirigirse a la plataforma web o a la 
                        correspondiente aplicación de QUD y solicitar el servicio mediante dichos medios telemáticos. El servicio básico 
                        consiste en la recogida de un producto y su posterior entrega, en ambas direcciones establecidas por el Cliente 
                        siempre que se encuentren exclusivamente dentro del territorio de actuación de QUD. Asimismo, el Cliente puede 
                        solicitar a QUD la compra presencial de productos en su nombre, los recoja y los entregue en las direcciones 
                        consignadas y según las indicaciones y especificaciones brindadas por este último. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El Cliente es el único responsable en la correcta consignación de las direcciones de entrega y recogida en la 
                        Plataforma, por lo que exime a QUD de cualquier negligencia o error en la recogida o entrega del pedido derivada 
                        de la consignación errónea de las direcciones. Como consecuencia de ello, será el Cliente quién deba asumir el 
                        costo derivado de la incorrecta consignación de las direcciones de entrega y recogida en la Plataforma. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Si usted ordena cualquier producto, por el presente documento usted representa y garantiza que tiene 18 años de edad 
                        o más o que tiene la debida autorización de sus padres o representantes, se obliga a pagar la totalidad de los precios de cualquier compra que haga, ya sea con tarjeta de crédito/débito/transferencia concurrente con su orden en línea o por otro medio de pago aceptable para QUD. Usted se obliga a pagar todos los impuestos aplicables.  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        En los supuestos en los que el Cliente no se encontrase en el lugar consignado para la entrega, QUD conservará 
                        el producto durante 24 horas, o 20 minutos en el caso de productos perecederos. Asimismo, el Cliente deberá asumir 
                        el 100% del costo del servicio de mensajería básico, así como del precio del producto en el caso de que se haya 
                        adquirido o contratado por encargo del Cliente, y deberá volver a pagar otro servicio para recibir los productos 
                        no entregados. QUD en ningún caso será responsable del deterioro o de la caducidad del producto objeto del encargo 
                        en este caso. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El cliente puede estar en contacto permanente con nuestros operadores para cualquier eventualidad referida a su pedido. 
                    </Typography>




                    <br/><br/>
                    {/** 4 - TARIFAS DE LOS SERVICIOS, FACTURACIÓN Y DEVOLUCIÓN:      */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        4.TARIFAS DE LOS SERVICIOS, FACTURACIÓN Y DEVOLUCIÓN:  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El uso de la Plataforma para los Clientes permite tener un costo asociado al producto o servicio requerido, 
                        QUD se hace responsable únicamente por las tarifas asociadas a sus servicios de delivery, más no asume 
                        responsabilidad alguna en los precios fijados por terceros o aliados. En caso de alguna modificación en los 
                        precios de los comercios aliados referidos en la plataforma, el usuario se compromete a pagar la diferencia 
                        o notificar la cancelación del pedido antes de los 10 minutos de ejecutarse, para poder ser reversado en 
                        las 24 horas hábiles siguientes, posterior a esos 10 minutos de tiempo de notificación QUD se reserva el 
                        derecho de devolución. QUD se reserva el derecho a cancelar un pedido sin necesidad de alegar causa justa. 
                        En caso de cancelación instada por QUD, el Usuario tendrá derecho al reembolso de la cantidad abonada en las 
                        24 horas hábiles siguientes. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El Usuario autoriza a QUD para que le remita por medios telemáticos, a la dirección de correo electrónica 
                        facilitada por el mismo durante el proceso de registro, el recibo de los servicios contratados y/o las 
                        facturas generadas. En caso de requerir una factura, el usuario deberá añadir los datos fiscales a la 
                        plataforma antes de hacer el pedido. 
                    </Typography>



                    <br/><br/>
                    {/** 5 - CÓDIGOS PROMOCIONALES:      */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        5.CÓDIGOS PROMOCIONALES:    
                    </Typography>

                    <Typography style={styles.Text}>
                        Los códigos promocionales deberán ser correctamente consignados en la aplicación con anterioridad a la 
                        realización del pedido, en caso contrario su uso no será efectivo para el Usuario y no podrá disfrutar 
                        de los mismos. Los códigos promocionales no son acumulables. El Usuario solamente podrá beneficiarse 
                        de 01 (un) código promocional por orden. 
                    </Typography>



                    <br/><br/>
                    {/** 6 - POLÍTICA DE PRODUCTOS:       */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        6.POLÍTICA DE PRODUCTOS:   
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Los Usuarios que realicen un pedido que incluya la adquisición y/o entrega de bebidas alcohólicas y/o 
                        cigarrillos a través de la plataforma deben ser mayores de edad, habiendo cumplido los 18 años. QUD se 
                        reserva el derecho de negarse a permitir el pedido de compra y/o entrega de alcohol y/o cigarrillos a 
                        cualquier persona que no pueda probar tener al menos 18 años de edad. La presente cláusula será de 
                        idéntica aplicación a cualquier otro producto y/o servicio análogo reservado a la mayoría de edad según 
                        legislación vigente y que sea solicitado por un Usuario a través de la Plataforma. 
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD no se hace responsable de aceptar pedido de medicamentos que requieran prescripción médica obligatoria. 
                        En cualquier caso, QUD no se hace responsable del uso que hagan los Usuarios de los productos solicitados 
                        en la sección de Farmacia, así como tampoco responderá por las cantidades y/o condiciones de los productos 
                        que se dispensen en las oficinas de farmacia.     
                    </Typography>
                    

                    <Typography paragraph style={styles.Text}>
                        QUD no tiene permitida en sus políticas la entrega y/o retiro de los siguientes productos ni cualquier otro 
                        no explicito que comprometa a integridad de nuestros repartidores, equipo y/o marca. 
                    </Typography>
                    

                    <div style={{marginLeft:20}}>
                        <Typography paragraph style={styles.Text}>
                            • Animales y Especies Reguladas.
                        </Typography>
        
                        <Typography paragraph style={styles.Text}>
                            • Drogas y/o sustancias ilícitas.
                        </Typography>
        
                        <Typography paragraph style={styles.Text}>
                            • Explosivos y/o armas detonadoras, dispositivos pirotécnicos.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Combustibles y/o líquidos inflamables, químicos regulados.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Pornografía infantil.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Copias no autorizadas de libros, música, películas y otros materiales protegidos o por licencia.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Falsificaciones y productos desautorizados.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Dispositivos o herramientas para desbloquear medidas de seguridad.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Partes humanas, órganos u otras partes del cuerpo; fluidos corporales; células madre; embriones.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Bienes robados o ilegales.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Equipamiento de telecomunicaciones ilegal.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Bienes ofensivos, productos, u otro material que difame alguna persona o grupo de personas basándose 
                            en su raza, etnia, origen nacional, religión, sexo, u otro factor; promueva o apoye membresía en grupos 
                            terroristas u otras organizaciones prohibidas. 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Bienes ofensivos relacionados con un crimen.
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Piedras o materiales raros, escasos o valiosos. 
                        </Typography>

                        <Typography paragraph style={styles.Text}>
                            • Artefactos culturalmente protegidos. 
                        </Typography>
                    </div>
                    

                    <br/><br/>
                    {/** 7 - DERECHOS DE PROPIEDAD.        */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        7.DERECHOS DE PROPIEDAD.  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD es dueña única y exclusiva, de todos los derechos, título e intereses en la App, Sitio Web y sus 
                        plataformas asociadas, de todo el contenido (incluyendo, vídeos, audio, fotografías, ilustraciones, 
                        gráficos, otros medios visuales, copias, textos, software, títulos, productos, etc.), códigos, datos 
                        y materiales del mismo, el aspecto y el ambiente, el diseño y la organización de la plataforma y la 
                        compilación de los contenidos, incluyendo pero no limitado a, cualesquiera derechos de autor, derechos 
                        de marca, derechos de patente, derechos de base de datos, derechos morales y otras propiedades 
                        intelectuales y derechos patrimoniales del mismo. El uso de la plataforma no le otorga propiedad de 
                        ninguno de los contenidos, códigos, datos o materiales a los que pueda acceder en o a través de la 
                        plataforma.
                    </Typography>


                    <br/><br/>
                    {/** 8 - LICENCIA LIMITADA.        */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        8.LICENCIA LIMITADA:   
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Usted puede acceder y ver el contenido de la plataforma de QUD, desde su computadora o desde cualquier 
                        otro dispositivo y, a menos de que se indique de otra manera en estos Términos y Condiciones, sacar 
                        impresiones individuales del contenido de la plataforma para su uso personal, interno e informativo 
                        únicamente. El uso de la plataforma y de los servicios que se ofrecen en o a través de QUD, son sólo 
                        para su uso personal, no comercial de carácter reventa u otro en nombre de la marca QUD, excepto previa 
                        autorización escrita de la marca. Cualquier distribución, publicación o explotación comercial o promocional 
                        de la plataforma, o de cualquiera de los contenidos, códigos, datos o materiales en la plataforma, está 
                        estrictamente prohibida, a menos de que usted haya recibido el previo permiso expreso por escrito de los 
                        socios propietarios de QUD. A no ser como está expresamente permitido en el presente contrato, usted no 
                        puede informar, exponer, publicar, copiar, reproducir, distribuir, transmitir, modificar, ejecutar, 
                        difundir, transferir, vender o de cualquier otra manera explotar cualquiera de los contenidos, publicidades, 
                        códigos, datos disponibles a través de la plataforma. Usted se obliga además a no alterar, editar, borrar, 
                        quitar, o de otra manera cambiar el significado o la apariencia de, o cambiar el propósito de, cualquiera de 
                        los contenidos, códigos, datos o materiales en o disponibles a través de la plataforma. Usted reconoce que no 
                        adquiere ningún derecho de propiedad al tener acceso al Sitio Web de QUD. Si usted hace otro uso de la plataforma, 
                        usted puede violar las leyes de derechos de autor y puede ser sujeto a responsabilidad legal por dicho uso 
                        no autorizado. 
                    </Typography>



                    <br/><br/>
                    {/** 9 - MARCAS COMERCIALES.         */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        9.MARCAS COMERCIALES:  
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Las marcas comerciales, logos, marcas de servicios, marcas registradas expuestas en la plataforma de QUD o en 
                        los contenidos disponibles son Marcas Comerciales de QUD registradas y no registradas y/o de socios y aliados 
                        comerciales, y no pueden ser usadas con respecto a productos y/o servicios que no estén relacionados, asociados 
                        o patrocinados por sus poseedores de derechos y que puedan causar confusión a los clientes, o de alguna manera 
                        que denigre o desacredite a sus poseedores de derechos. Todas las Marcas Comerciales que no sean de QUD que aparezcan 
                        en la plataforma, son propiedad de sus respectivos dueños. Nada que esté contenido en la plataforma deberá ser interpretado 
                        como otorgando, por implicación, desestimación, o de otra manera, alguna licencia o derecho para usar alguna Marca Comercial 
                        expuesta en la plataforma sin el permiso escrito de QUD o de terceros que puedan ser dueños de dicha Marca Comercial. 
                        El mal uso de las Marcas Comerciales expuestas en la plataforma está estrictamente prohibido. 
                    </Typography>



                    <br/><br/>
                    {/** 10.CAMBIOS DE TÉRMINOS DE USO          */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        10.CAMBIOS DE TÉRMINOS DE USO.   
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD se reserva el derecho, a su discreción, de cambiar, modificar, añadir o quitar cualquier porción de los Términos 
                        y Condiciones, en cualquier momento. Los cambios en los Términos y Condiciones serán efectivos cuando se publiquen. 
                        La continuación del uso de la plataforma y/o de los servicios puestos a disposición después de haber sido publicado 
                        cualquier cambio, será considerado como aceptación de esos cambios. 
                    </Typography>


                    <br/><br/>
                    {/** 11.CAPACIDAD          */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        11.CAPACIDAD   
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Los servicios ofrecidos en QUD, actualmente solo están disponibles para la República Bolivariana de Venezuela, 
                        por tanto, para poder acceder a ellos, usted deberá ser un residente o nacionalizado en dicho país, con suficiente 
                        capacidad legal para contratar. No podrán utilizar los servicios acá ofrecidos, aquellas personas que no posean dicha 
                        capacidad, entendiéndose como tales, los menores de edad (sin la previa autorización de los padres o representantes) o 
                        aquellos usuarios, que por alguna razón hayan sido suspendidos temporalmente o definitivamente del Sitio. 
                    </Typography>


                    <br/><br/>
                    {/** 12. GARANTÍA DE ERRORES            */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        12. GARANTÍA DE ERRORES    
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD no garantiza la inexistencia de errores en el acceso a la web, o en su contenido; en el caso de que se citen 
                        o reproduzcan publicaciones de carácter oficial, dicha reproducción tendrá carácter meramente informativo debiendo 
                        considerarse a todos los efectos como válida la publicación en su boletín o fuente oficial correspondiente.
                    </Typography>


                    <br/><br/>
                    {/** 13. Disponibilidad del servicio            */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        13. DISPONIBILIDAD DEL SERVICIO.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        QUD se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y 
                        actualizaciones sobre la información contenida en su web o en su configuración o presentación. QUD no garantiza 
                        la disponibilidad y continuidad del funcionamiento de la web y de los servicios. Cuando ello sea razonablemente 
                        posible, se advertirá previamente las interrupciones en el funcionamiento de la web y de los servicios. El Usuario
                        exhonera a QUD de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la 
                        falta de disponibilidad o de continuidad del funcionamiento de la web y de los servicios, a la defraudación de la 
                        utilidad que los usuarios hubieren podido atribuir a la web y a los servicios, a la falibilidad de la web y de los 
                        servicios, y en particular, aunque no de modo exclusivo, a los fallos en el acceso a las distintas páginas web o a 
                        aquellas desde las que se prestan los servicios.  
                    </Typography>



                    <br/><br/>
                    {/** 14. RESPONSABILIDAD POR EL USO DE LA INFORMACIÓN CONTENIDA           */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        14. RESPONSABILIDAD POR EL USO DE LA INFORMACIÓN CONTENIDA.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        El/la usuario/a es consciente y acepta voluntariamente que el uso de la web, de los servicios y de los contenidos 
                        tiene lugar, en todo caso, bajo su única y exclusiva responsabilidad. Tanto el acceso a la web, como el uso que pueda 
                        hacerse de la información contenida en el mismo son de la exclusiva responsabilidad de quien lo realiza. QUD no responderá 
                        de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso de información ni de la aplicación 
                        que de ellos se realice para apoyar cualquier opinión o decisión personal o empresarial. QUD no asume responsabilidad alguna 
                        derivada de la conexión o contenidos de los enlaces de terceros a los que se hace referencia en la web. 
                    </Typography>



                    <br/><br/>
                    {/** 15. PROPIEDAD INTELECTUAL            */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        15. PROPIEDAD INTELECTUAL     
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        Los derechos de propiedad intelectual de la web/app, así como de sus distintos contenidos son titularidad de QUD Queda 
                        terminantemente prohibido realizar cualquier alteración de esta página. QUD no asume ninguna responsabilidad que se pueda 
                        derivar de manipulaciones o alteraciones no autorizadas. QUD no concede ninguna licencia o autorización de uso de ningún 
                        tipo sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con 
                        la web, los servicios o los contenidos. Queda prohibida la reproducción total o parcial de los contenidos de esta web sin 
                        citar su origen o sin solicitar autorización expresa para ello. La utilización no autorizada de la información contenida en 
                        esta web, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de QUD dará lugar al 
                        ejercicio de las acciones que correspondan y, en su caso, a las responsabilidades que de dicho ejercicio se deriven.  
                    </Typography>


                    <br/><br/>
                    {/** 16. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL          */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        16. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        En los supuestos en los que para acceder a alguna de las páginas o servicios de QUD sea necesaria la recogida de datos de 
                        carácter personal, se informa que los mismos serán tratados de conformidad con lo establecido en la normativa vigente 
                        relativa a la Protección de Datos de Carácter Personal y demás normas de desarrollo, conforme a lo establecido en el AVISO 
                        DE PRIVACIDAD DE ESTA WEB y al consentimiento que el/la/los usuarios deben aceptar previamente a efectuar cualquier tipo de 
                        comunicación con QUD desde esta página web. En el caso de que nos facilite su dirección de correo electrónico, se entiende 
                        que está prestando el consentimiento y aceptando este AVISO LEGAL y el AVISO DE PRIVACIDAD, para que la misma sea utilizada 
                        para el envío de comunicaciones relacionadas con los servicios prestados o a prestar y responder a sus consultas o peticiones 
                        por QUD.  
                    </Typography>



                    <br/><br/>
                    {/** 17. LEY APLICABLE Y JURISDICCIÓN          */}
                    <Typography variant='body1' style={styles.Subtitle}>
                        17. LEY APLICABLE Y JURISDICCIÓN.
                    </Typography>

                    <Typography paragraph style={styles.Text}>
                        La relación entre QUD y el Usuario, se regirá e interpretará de conformidad con las Condiciones Generales que en materia 
                        de interpretación, validez y ejecución se regirán por la legislación venezolana; y cualquier controversia se someterá a 
                        los Tribunales locales de la dirección fiscal de QUD. 
                    </Typography>

                    <br/><br/>
                    <Typography paragraph style={styles.Text}>
                        Cualquier comentario, información, sugerencia u otro puede comunicarse a través de:  info@qud.life
                    </Typography>

                    <br/><br/>
                    <Typography paragraph style={styles.Text}>
                        <strong>Última actualización:</strong> 19 de febrero de 2021
                    </Typography>

                </Grid>
            </Grid>
        </Container>
    )
}



