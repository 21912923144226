import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Copyright from '../../components/copyRight';

import logo from '../../assets/images/logos/logoQUDW.png'
import MainListItems from './mainList';
import { SwipeableDrawer } from '@mui/material';
import SecurityListItems from './securityList';
import { useContext } from 'react';
import BranchContext from '../../navigation/context/branchContext';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const drawerWidth = 240;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function AdminLayout(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    //const history = useHistory();
    

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex',bgcolor:'#FFF' }}>
            <CssBaseline />
            <AppBar sx={{bgcolor:'#FFF',borderRadius:0,color:'primary.main',padding:1}} position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography fontWeight={'bold'} variant="h6" noWrap component="div">
                        {
                            selectedBranch !== null
                            ?selectedBranch.name
                            :'SAFF'
                        }
                    </Typography>
                </Toolbar>
            </AppBar>

            <SwipeableDrawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    position:'relative'
                }}
                variant="persistent"
                anchor="left"
                open={open}
                PaperProps={{
                    sx:{
                        backgroundColor:'primary.main',
                        color:'primary.contrastText',
                        borderRadius:0
                    }
                }}
            >
                <DrawerHeader >
                    <img style={{width:'100%'}} src={logo} alt='QUD'/>
                </DrawerHeader>
                <Divider />
                <List>
                    <MainListItems/>
                    <SecurityListItems/>
                </List>
                <Divider />
            </SwipeableDrawer>

            <Box component="main" sx={{ flexGrow: 1,bgcolor:'#FFF',mt:3 }}>
                <Box sx={{ p: 3, bgcolor:'#FFF', minHeight:'95vh' }}>
                    <DrawerHeader />
                    {props.children}
                </Box>
                <Copyright/>
            </Box>
            
        </Box>
    );
}



/*import React, { useState, useContext } from 'react';
import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, List, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Copyright from '../../components/copyRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainListItems from './mainList';

import AuthContext from '../../navigation/context/authContext';
import { useHistory } from 'react-router-dom';

export default function AdminLayout(props)
{
    const {signOut} = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen,setIsOpen] = useState(false);

    const history = useHistory();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTogleDrawer = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <Box>
            <AppBar position='fixed' sx={{p:1,borderRadius:0}}>
                <Container maxWidth='xl'>
                <Toolbar variant='dense' disableGutters>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleTogleDrawer}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        CIVO
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={(handleClose)}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                open={isOpen}
                onClose={handleTogleDrawer}
            >
                <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',padding:0}}>
                    <Typography>CIVO</Typography>
                    <IconButton style={{marginLeft:155}} onClick={handleTogleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <MainListItems/>
                </List>
                <Divider />
                <List>
                    lista secundaria
                </List>
                <List onClick={()=>handleSignOut()}>
                    Salir
                </List>
            </Drawer>
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    mt:10,
                    minHeight: '85vh',
                    overflow: 'auto',
                  }}
            >
                {props.children}
                <br/><br/>
            </Box>
            <Copyright/>
        </Box>
    )
}*/