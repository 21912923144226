import { useContext, useEffect, useReducer, useState } from "react";
import { Button, Container, Divider, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import BranchContext from "../../../navigation/context/branchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { createExpenses, getDataToStore } from "../../../controllers/admin/ExpensesController";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";


const initialState = {
    isLoading : false,
    open: false,
    title: '',
    message: '',
    success: false
};

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default function ExpensesCreate()
{
    const { selectedBranch } = useContext(BranchContext);
    const [expenses,setExpenses] = useState([]);

    const [expenseTypes,setExpenseTypes] = useState([]);
    const [entities,setEntities] = useState([]);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        isLoading,
        open,
        title,
        message,
        success
    } = state


    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleAddExpenseDetail();
        handleGetDataToStoreExpense();
    },[]);

    const handleGetDataToStoreExpense = async () => {
        let response = await getDataToStore(selectedBranch.id)
        if(response.success === true)
        {
            setExpenseTypes(response.data.expenseTypes);
            setEntities(response.data.entities);
        }
    }

    const handleAddExpenseDetail = () => {
        setExpenses((prevexpenses)=>{
            const _expenses = [...prevexpenses];
            _expenses.push({
                date: moment().format(),
                expense_type_id: null,
                description:'',
                amount:'',
                entity_id:null
            });
            return _expenses;
        });
    }

    const handleUpdateExpenseDetail = async (_index,date,expense_type_id,description,amount,entity_id) => {
        setExpenses((prevDetails)=>{
            const _expenses = [...prevDetails];
            const detailToUpdate = _expenses[_index];

            if(detailToUpdate)
            {
                if(date !== null)
                    _expenses[_index].date = date;

                if(expense_type_id !== null)
                    _expenses[_index].expense_type_id = expense_type_id;

                if(description !== null)
                    _expenses[_index].description = description;

                if(amount !== null)
                    _expenses[_index].amount = amount;

                if(entity_id !== null)
                    _expenses[_index].entity_id = entity_id;
            }

            return _expenses;
        });
    }

    const handleRemoveItemDetail = (_index) => {
        setExpenses((prevDetails)=>
            prevDetails.filter((item,index)=>index !== _index)
        );
    }

    const handleSubmit = async () => {
        setState({field:'isLoading',value:true});
        
        let response = await createExpenses(selectedBranch.id,expenses);
        
        if(response.success === true)
        {
            setState({field:'title',value:response.message.title});
            setState({field:'message',value:`${response.message}`});
            setState({field:'success',value:true});
            setState({field:'open',value:true});
        }
        else
        {
            setState({field:'title',value:response.message.title});
            setState({field:'message',value:response.message});
            setState({field:'success',value:false});
            setState({field:'open',value:true});
        }
        setState({field:'isLoading',value:false});
    }

    const handleClose = () => {
        if(success === true)
            history.push(`/expenses`)
        else
            setState({field:'open',value:false})
    }

    return(
        <Container >
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Gastos`},{name:`Crear`}]}/>
            <SectionTitle title={'Gastos'} subtitle='Crear'/>
            
            <Grid container spacing={3} justifyContent={'center'}>
                {
                    expenses.length !== 0 &&
                    expenses.map((detail,index)=>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={3} justifyContent={'flex-end'}>
                                {/**date */}
                                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Fecha"
                                            value={detail.date}
                                            onChange={(newValue)=>handleUpdateExpenseDetail(index,newValue,null,null,null,null)}
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item lg={2} xl={2}>
                                    <TextField
                                        fullWidth
                                        label='Tipo de Gasto'
                                        select
                                        value={detail.expense_type_id}
                                        onChange={(e)=>handleUpdateExpenseDetail(index,null,e.target.value,null,null,null)}
                                    >
                                        <MenuItem value={null}>Selecciona un tipo de gasto</MenuItem>
                                        {
                                            expenseTypes.length !== 0 &&
                                            expenseTypes.map(item=>
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            )
                                        }
                                    </TextField>
                                </Grid>

                                <Grid item lg={2} xl={2}>
                                    <TextField
                                        fullWidth
                                        sx={{
                                            '& input[type="text"]': {
                                                textAlign: 'right', // Align text to the right
                                            },
                                        }}
                                        label='Monto'
                                        value={detail.amount}
                                        onChange={(e)=>handleUpdateExpenseDetail(index,null,null,null,e.target.value,null)}
                                    />
                                </Grid>

                                <Grid item lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        label='Descripcion'
                                        value={detail.description}
                                        onChange={(e)=>handleUpdateExpenseDetail(index,null,null,e.target.value,null,null)}
                                    />
                                </Grid>

                                <Grid item lg={2} xl={2}>
                                    <TextField
                                        fullWidth
                                        label='Persona/Empleado'
                                        select
                                        value={detail.entity_id}
                                        onChange={(e)=>handleUpdateExpenseDetail(index,null,null,null,null,e.target.value)}
                                    >
                                        <MenuItem value={null}>Selecciona una persona</MenuItem>
                                        {
                                            entities.length !== 0 &&
                                            entities.map(item=>
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            )
                                        }
                                    </TextField>
                                </Grid>
                                
                                <Grid item lg={1} xl={1} justifyContent={'flex-end'}>
                                    <IconButton fullWidth size="small" color="error" onClick={()=>handleRemoveItemDetail(index)} sx={{mb:2}}>
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider sx={{mt:1}}/>
                        </Grid>
                    )
                }
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3} justifyContent={'space-between'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button onClick={()=>handleAddExpenseDetail()}>
                                <Typography>
                                    <FontAwesomeIcon icon={faPlus}/>
                                    &nbsp;Agregar Detalle
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <Button fullWidth variant="contained" onClick={()=>handleSubmit()}>
                                <Typography fontWeight={'bold'}>
                                    <FontAwesomeIcon icon={faPlus}/>
                                    &nbsp;Registrar Gastos
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={success === true ? 'Finalizar' : 'Cerrar'}
                onClick={()=>handleClose()}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />
        </Container>
    )
}