import { useContext, useEffect, useReducer, useState } from "react";
import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { getAllOrders } from "../../../controllers/admin/OrdersController";
import OrdersDataTable from "./dataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import QUDProgress from "../../../components/progress";




export default  function Orders()
{
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const [orders,setOrders] = useState([]);
    const [statistics,setStatistics] = useState();

    const [startDate,setStartDate] = useState(moment().startOf('month'));
    const [endDate,setEndDate] = useState(moment().add(1, 'month').startOf('month'));

    const [isLoading,setIsLoading] = useState(false);

    const { type } = useParams();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetOrders(_startDate,_endDate);
    },[]);

    const handleGetOrders = async (_startDate,_endDate) => {
        setIsLoading(true);
        let response = await getAllOrders(selectedBranch.id,type,_startDate,_endDate);
        if(response.success === true)
        {
            setOrders(response.data.orders);
            setStatistics(response.data.statistics);
            console.log('ORDERS RESPONSE ==> ',response);   
        }
        setIsLoading(false);
    }

    const handleType = () => {
        if(type === 'inbound')
            return 'Entrada';
        else
            return 'Salida';
    };

    const handleSearch = async () => {
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetOrders(_startDate,_endDate);
    }

    return(
        <Container maxWidth="xl">
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Ordenes / ${handleType()}`}]}/>
            <SectionTitle title={'Ordenes de '+handleType()}/>

            <Grid container spacing={3} mb={3} justifyContent={'center'}>
                <Grid item lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha inicial"
                                        //minDate={moment.now()}
                                        
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha final"
                                        //minDate={moment.now()}
                                        
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} xl={2}>
                            <Button
                                fullWidth 
                                sx={{height:'100%'}}
                                variant="contained"
                                onClick={()=>handleSearch()}
                            >
                                <FontAwesomeIcon size="2x" icon={faSearch}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={5} xl={5} justifyContent={'center'}>
                                <Typography variant="h6" fontWeight={'bold'}># de Ordenes</Typography>
                                <Typography variant="h4" fontWeight={'bold'}>
                                    {statistics?.total_orders} 
                                </Typography>
                            </Grid>
                            
                            <Grid item lg={5} xl={5} justifyContent={'center'}>
                                <Typography variant="h6" fontWeight={'bold'}>Total</Typography>
                                <Typography variant="h4" fontWeight={'bold'}>
                                    {statistics?.total_amount} $
                                </Typography>
                            </Grid>
                            <Grid item lg={2} xl={2} justifyContent={'center'}>
                                <Button fullWidth variant="contained" onClick={()=>redirect(`${history.location.pathname}/create`)}>
                                    <Typography variant="h6">
                                        Agregar
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {
                    orders.length !== 0 &&
                    <Grid item lg={12} xl={12}>
                        <OrdersDataTable type={handleType()} orders={orders}/>
                    </Grid>
                }
            </Grid>

            <QUDProgress open={isLoading}/>
        </Container>
    )
}