import { useContext, useEffect, useReducer, useState } from "react";
import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import { getAllcategories } from "../../../controllers/admin/ProductsController";
import CategoriesDataTable from "./dataTable";
import { get_categories } from "../../../controllers/admin/CategoriesController";




export default  function Categories()
{
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const [categories,setCategories] = useState([]);
    const [statistics,setStatistics] = useState();
    const { type } = useParams();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        handleGetCategories();
    },[]);

    const handleGetCategories = async () => {
        let response = await get_categories(selectedBranch.id);
        if(response.success === true)
        {
            setCategories(response.data); 
        }
    }



    return(
        <Container maxWidth="xl">
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Categorías`}]}/>
            <SectionTitle title={'Categorías'}/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <Button fullWidth variant="contained" onClick={()=>redirect(`${history.location.pathname}/create`)}>
                        <Typography variant="h6">
                            Agregar
                        </Typography>
                    </Button>
                </Grid>
                {
                    categories.length !== 0 &&
                    <Grid item lg={12} xl={12}>
                        <CategoriesDataTable categories={categories}/>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}