import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Map, GoogleApiWrapper } from "google-maps-react";



function GoogleMaps(props)
{
    const [initialCenter,setInitialCenter] = useState({lat:10.189640,lng:-64.688094});
    
    var options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0, }; 
    
    function success(pos){ 
        var crd = pos.coords; 
        setInitialCenter({lat:crd.latitude, lng:crd.longitude});
    }
    
    function errors(err){
        console.warn(`ERROR(${err.code}): ${err.message}`); 
    }
    
    useEffect(() => { 
        if(navigator.geolocation)
        { 
            navigator.permissions.query({ name: "geolocation" })
            .then((result)=>{ 
                console.log('GEOLOCALIZACION ===> ',result); 
                if(result.state === 'granted')
                {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                }
                else if(result.state === 'prompt')
                {
                    navigator.geolocation.getCurrentPosition(success, errors, options);
                }
                else if(result.state === 'denied')
                {

                }
                else
                {
                    console.log("Geolocation is not supported by this browser.");
                }
            }); 
        }
        else
        {
            console.log("Geolocation is not supported by this browser."); 
        } 
    },[]);

    const containerStyle = {
        position: 'relative',  
        width: '100%',
        height: props.height || '100%',
    }

    return(
        <Map
            google={window.google}
            containerStyle={containerStyle}
            zoom={15}
            mapTypeControl={false}
            streetViewControl={false}
            scaleControl={true}
            fullScreenControl={false}

            initialCenter={props.initialCenter || initialCenter}
        >
            {props.children}
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE',
    //language: 'es'
    
})(GoogleMaps);