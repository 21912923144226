import { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import UserContext from "../../../navigation/context/userContext";
import { GetDatos, UpdateDatos } from "../../../controllers/company/CompanyProfileController";


import ModalDialog from "../../../components/modalDialog";
import AuxAlert from "../../../components/alert";
import DatosGenerales from "./datosGenerales";
import Ubicacion from "./ubicacion";
import Contactos from "./contactos";
import Socials from "./Socials";
import WorkingHours from "./workingHours";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
            )}
        </div>
    );
  }

export default function CompanyInfo()
{
    const {userData,setUserData} = useContext(UserContext);
    const [categories,setCategories] = useState([]);
    
    const [category,setCategory] = useState('');
    const [errorCategory,setErrorCategory] = useState('');

    const [tradeName,setTradeName] = useState('');
    const [errorTradeName,setErrorTradeName] = useState(false);

    const [description,setDescription] = useState('');
    const [errorDescription,setErrorDescription] = useState(false);

    const [slogan,setSlogan] = useState('');

    const [address,setAddress] = useState(userData.user.direccion);
    const [errorAddress,setErrorAddress] = useState(false);
    const [coordinates,setCoordinates] = useState(undefined);
    const [center,setCenter] = useState({lat:userData.user.latitud, lng: userData.user.longitud});

    const [phone,setPhone] = useState('');

    const [tabValue,setTabValue] = useState(0);
    const [isDisabled,setIsDisabled] = useState(false);

    const [open,setOpen] =useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);



    useEffect(()=>{
        getInfo();
    },[])

    const getInfo = async () => {
        let response = await GetDatos();
        if(response.success === true)
        {
            setCategories(response.data.categorias);
            setCategory(response.data.empresa.Categoria)
            setTradeName(response.data.empresa.NombreComercial);
            setDescription(response.data.empresa.descripcion);
            setSlogan(response.data.empresa.slogan);
            setPhone(response.data.empresa.telefono);
            setAddress(response.data.empresa.direccion);
            setCoordinates({
                lat:parseFloat(response.data.empresa.lat),
                lng:parseFloat(response.data.empresa.lng)
            });

        }
    }

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    


    return(
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabValueChange}  aria-label="basic tabs example" variant="scrollable" scrollButtons='auto'>
                        <Tab label="Datos generales"/>
                        <Tab label="Ubicación"/>
                        <Tab label="Datos de contacto" />
                        <Tab label="Redes Sociales" />
                        <Tab label="Horarios de Trabajo" />
                    </Tabs>
                    
                    
                    {/**Datos Generales */}
                    <TabPanel value={tabValue} index={0}>
                        {
                            categories.length !== 0 &&
                            <DatosGenerales
                                categories={categories}
                                category={category}
                                description={description}
                                tradeName={tradeName}
                                slogan={slogan}
                            />
                        }
                    </TabPanel>
                    
                    
                    {/**Ubicacion */}
                    <TabPanel value={tabValue} index={1}>
                        {
                            coordinates !== undefined &&
                            <Ubicacion
                                coordinates={coordinates}
                                address={address}
                                center={coordinates}
                            />
                        }
                    </TabPanel>

                    {/**Contactos */}
                    <TabPanel value={tabValue} index={2}>
                        <Contactos
                            phone={phone}
                            email={userData.user.email}
                        />
                    </TabPanel>

                    {/**Redes Sociales */}
                    <TabPanel value={tabValue} index={3}>
                        <Socials/>
                    </TabPanel>

                    {/**Horarios de Trabajo */}
                    <TabPanel value={tabValue} index={4}>
                        <WorkingHours/>
                    </TabPanel>
                </Box>
            </Box>
            
            <ModalDialog
                title={title}
            />
            
        </Grid>
    )
}