import { useContext, useEffect, useReducer, useState } from "react";
import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Autocomplete, Box, Button, Container, createFilterOptions, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import CustomBreadcrumbs from "../../../components/breadcrumbs";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle, faSearch, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { create_order, get_data_to_store } from "../../../controllers/admin/OrdersController";
import moment from "moment";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import UserContext from "../../../navigation/context/userContext";

const filter = createFilterOptions();

const initialState = {
    entities:[],
    employees:[],
    products:[],
    entity:null,
    entityErrorMessage:'',

    currency: 'USD',
    paymentType:'cash',
    date: new moment().format(),
    date_production: new moment().format(),
    number: '',
    notes:'',

    isLoading : false,
    open: false,
    title: '',
    message: '',
    success: false

};

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default  function OrdersCreate()
{
    const {userData} = useContext(UserContext);
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const { type } = useParams();

    const [entitiesDialogIsOpen,setEntitiesDialogIsOpen] = useState(false);
    //const [date,setDate] = useState(moment().format());
    const [details,setDetails] = useState([]);
    const [total,setTotal] = useState(0);
    const [totalFF,setTotalFF] = useState(0);
    const [state,setState] = useReducer(reducer,initialState);
    const {
        entities, employees, products, entity,
        currency, paymentType, date_production, date, number, notes,
        isLoading,
        open,
        title,
        message,
        success
    } = state

    const history = useHistory();

    useEffect(()=>{
        handleGetDataToStoreOrder();
    },[]);


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const handleGetDataToStoreOrder = async () => {
        let response = await get_data_to_store(
            type==='inbound'?'supplier':'customer',
            selectedBranch.id
        );
        if(response.success === true)
        {
            /*let _details = [];
            let _products = response.data.products;
            if(_products.length !== 0)
            {
                await Promise.all(_products.map(item=>
                    _details.push({
                        id: item.id,
                        name: item.name,
                        unit_price: item.unit_price,
                        ff_price: item.ff_price,
                        quantity: 0
                    })
                ));

                setDetails(_details);
            }*/
            
            setState({field:'entities',value:response.data.entities});
            setState({field:'employees',value:response.data.employees});
            setState({field:'products',value:response.data.products});
        }
    }

    /**----------------------- Manejo de Detalles ---------------------*/
    const handleAddItemDetail = () => {
        setDetails((prevDetails)=>{
            const _details = [...prevDetails];
            _details.push({
                id: null,
                name: null,
                unit_price: '',
                ff_price: '',
                quantity_matadero:'',
                quantity: '',
                total:'',
                total_ff:'',
                notes:'',
                driver:''
            });
            return _details;
        });
    }

    const handleUpdateItemDetail = async (_index,productID,quantity_matadero,quantity,unitPrice,ffPrice,notes,driver) => {
        setDetails((prevDetails)=>{
            const _details = [...prevDetails];
            const detailToUpdate = _details[_index];

            if(detailToUpdate)
            {  
                if(productID)
                {
                    let product = products.findIndex((item)=> item.id === productID);
                    product = products[product];
                    _details[_index].id = product.id;
                    _details[_index].name = product.name;
                    if(type === 'inbound')
                    {
                        _details[_index].unit_price = '';
                        _details[_index].ff_price = '';       
                    }
                    else
                    {
                        _details[_index].unit_price = product.unit_price;
                        _details[_index].ff_price = product.ff_price;   
                    }
                }

                if(quantity_matadero !== null)
                    _details[_index].quantity_matadero = quantity_matadero;

                if(quantity !== null)
                    _details[_index].quantity = quantity;

                if(unitPrice)
                    _details[_index].unit_price = unitPrice;
                
                if(ffPrice )
                    _details[_index].ff_price = ffPrice;

                if(notes)
                    _details[_index].notes = notes;

                if(driver)
                    _details[_index].driver = driver;

                _details[_index].total_ff = _details[_index].quantity * _details[_index].ff_price;
                _details[_index].total = _details[_index].quantity * _details[_index].unit_price;

            }

            return _details;
        });
    }

    const handleRemoveItemDetail = (_index) => {
        setDetails((prevDetails)=>
            prevDetails.filter((item,index)=>index !== _index)
        );
        /*let _details = details;
        _details = _details.splice(_index,1);
        setDetails(_details);*/
    }

    useEffect(()=>{
        let total = 0;
        let total_ff = 0;
        total = details.reduce((accumulator,currentValue) => accumulator + currentValue.total,0);
        total_ff = details.reduce((accumulator,currentValue) => accumulator + currentValue.total_ff,0);
        setTotal(total);
        setTotalFF(total_ff);
    },[details]);


    /**-----------------------Fin Manejo de Detalles ---------------------*/

    const handleEntitySelect = async (_entity) => {
        setState({field:'entity',value:_entity});
        setEntitiesDialogIsOpen(false);
    }


    const handleType = () => {
        if(type === 'inbound')
            return 'Entrada';
        else
            return 'Salida';
    }


    const formVerification = () => {
        let ban = 0;

        if(entity === null)
        {
            setState()   
        }
    }


    const handleAddPielLibrillo = async () => {
        if(type === 'inbound')
        {
            let _cantidad = 0;
            await Promise.all(
                details.map(_detail=>{
                    if(_detail.name === 'Hembra' || _detail.name === 'Macho')
                    {
                        return _cantidad += parseFloat(_detail.quantity_matadero);
                    }
                })
            );

            if(_cantidad > 0)
            {
                let _details = details;
                _details.push({
                    id: 3,
                    name: 'Librillo',
                    unit_price: 0,
                    ff_price: 0,
                    quantity_matadero:_cantidad,
                    quantity: _cantidad,
                    total:0,
                    total_ff:0,
                    notes:'',
                    driver:0
                });

                _details.push({
                    id: 1,
                    name: 'Piel',
                    unit_price: 0,
                    ff_price: 0,
                    quantity_matadero:_cantidad,
                    quantity: _cantidad,
                    total:0,
                    total_ff:0,
                    notes:'',
                    driver:0
                });

                setDetails(details);   
            }
        }
    }


    const handleSubmit = async () => {
        setState({field:'isLoading',value:true});
        
        await handleAddPielLibrillo();
        let _date_production = null;
        if(selectedBranch.id === 1 && type === 'inbound')
            _date_production = moment(date_production).format('YYYY-MM-DD');

        let response = await create_order(selectedBranch.id,type,entity.id,currency,
            _date_production,
            moment(date).format('YYYY-MM-DD'),number,
            paymentType,total,totalFF,notes,details,[]
        );
        if(response.success === true)
        {
            setState({field:'title',value:response.message.title});
            setState({field:'message',value:`${response.message}`});
            setState({field:'success',value:true});
            setState({field:'open',value:true});
        }
        else
        {
            setState({field:'title',value:response.message.title});
            setState({field:'message',value:response.message});
            setState({field:'success',value:false});
            setState({field:'open',value:true});
        }
        setState({field:'isLoading',value:false});
    }

    const handleClose = () => {
        if(success === true)
            history.push(`/orders/${type}`)
        else
            setState({field:'open',value:false})
    }



    return(
        <Container maxWidth="xl">
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Ordenes / ${handleType()}`},{name:'Crear'}]}/>
            <SectionTitle title={'Orden de '+handleType()} subtitle='Crear'/>
            <Grid container spacing={3} justifyContent={'flex-end'}>

                {/**Order Data */}
                <Grid item lg={12} xl={12}>
                    <Paper elevation={4} >
                        {/**Provvedor / Cliente */}
                        <Grid container spacing={3} mb={5}>
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} xl={6}>
                                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                            Proveedor/Cliente
                                        </Typography>
                                        {
                                            entities.length !== 0 &&
                                            <Autocomplete
                                                value={entity}
                                                onChange={(event, newValue) => {
                                                    if (typeof newValue === 'string') {
                                                        handleEntitySelect(newValue)
                                                    
                                                    } else if (newValue && newValue.inputValue) {
                                                    // Create a new value from the user input
                                                    handleEntitySelect(newValue)
                                                    } else {
                                                        handleEntitySelect(newValue)
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);

                                                    
                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                id="entity"
                                                options={entities}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                    return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.inputValue) {
                                                    return option.inputValue;
                                                    }
                                                    // Regular option
                                                    return option.name;
                                                }}
                                                renderOption={(props, option) => {
                                                    const { key, ...optionProps } = props;
                                                    return (
                                                    <li key={key} {...optionProps}>
                                                        {option.name}
                                                    </li>
                                                    );
                                                }}
                                                sx={{ width: 300 }}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Selecciona un Proveedor/Cliente" />
                                                )}
                                            />
                                        }
                                        
                                        
                                    </Grid>
                                    {/**selected Entity */}
                                    <Grid item xs={12} lg={12} xl={12}>
                                        <Typography variant="h6">{entity?.name}</Typography>
                                        <Typography variant="body1">{entity?.tax_id}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        {/**Datos basicos de la factura */}
                        <Grid container spacing={3} mb={5}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                    fullWidth
                                    label='moneda'
                                    id="currency"
                                    name="currency"
                                    select
                                    value={currency}
                                    onChange={ onChange }
                                >
                                    <MenuItem value='USD'>USD - Dolares</MenuItem>
                                    <MenuItem value='VES'>VES - Bolivares</MenuItem>
                                </TextField>
                            </Grid>
                            {
                                (selectedBranch.id === 1) &&
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Fecha producción"
                                            value={date_production}
                                            id="date_production"
                                            name="date_production"
                                            onChange={(newValue) => setState({field:'date_production',value:newValue}) }
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            }
                            
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha"
                                        value={date}
                                        id="date"
                                        name="date"
                                        onChange={(newValue) => setState({field:'date',value:newValue}) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                    fullWidth
                                    label='Numero / Guia'
                                    id="number"
                                    name="number"
                                    value={number}
                                    onChange={onChange}
                                    
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                    fullWidth
                                    label='Tipo de pago'
                                    id="paymentType"
                                    name="paymentType"
                                    select
                                    value={paymentType}
                                    onChange={onChange}
                                >
                                    <MenuItem value='credit'>A credito</MenuItem>
                                    <MenuItem value='cash'>Al contado</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>

                        {/**Detalles de la factura */}
                        <Grid container spacing={3} mb={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h6" fontWeight={'bold'}>Detalles</Typography>
                                <Divider/>
                            </Grid>
                            {
                                details.length !== 0 &&
                                details.map((detail,index)=>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Grid container spacing={3} justifyContent={'flex-end'}>
                                            <Grid item xs={12} lg={2} xl={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label='Producto'
                                                    select
                                                    value={detail.id}
                                                    onChange={(e)=>handleUpdateItemDetail(index,e.target.value,null,null,null,null)}
                                                >
                                                    <MenuItem value={null}>Selecciona un producto</MenuItem>
                                                    {
                                                        products.length !== 0 &&
                                                        products.map(product=>
                                                            <MenuItem value={product.id}>{product.name}</MenuItem>
                                                        )
                                                    }
                                                </TextField>
                                            </Grid>
                                            {
                                                (selectedBranch.id === 1 && type === 'inbound') &&
                                                <Grid item xs={6} lg={1} xl={1}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label='producción'
                                                        value={detail.quantity_matadero.toString()}
                                                        onChange={(e)=>handleUpdateItemDetail(index,null,e.target.value,null,null,null)}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={6} lg={1} xl={1}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label={(selectedBranch.id === 1 && type === 'inbound') ? 'Recibido' : 'Cantidad'}
                                                    value={detail.quantity.toString()}
                                                    onChange={(e)=>handleUpdateItemDetail(index,null,null,e.target.value,null,null)}
                                                />
                                            </Grid>

                                            {
                                                userData.user.type === 1 &&
                                                <>
                                                    <Grid item xs={6} lg={2} xl={2}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            sx={{
                                                                '& input[type="text"]': {
                                                                    textAlign: 'right', // Align text to the right
                                                                },
                                                            }}
                                                            label='Precio'
                                                            value={detail.unit_price}
                                                            onChange={(e)=>handleUpdateItemDetail(index,null,null,null,e.target.value,null)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} lg={2} xl={2}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            sx={{
                                                                '& input[type="text"]': {
                                                                    textAlign: 'right', // Align text to the right
                                                                },
                                                            }}
                                                            label='Precio FF'
                                                            value={detail.ff_price}
                                                            onChange={(e)=>handleUpdateItemDetail(index,null,null,null,null,e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} lg={2} xl={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="outlined-adornment-amount">Total</InputLabel>
                                                            <OutlinedInput
                                                                disabled
                                                                size="small"
                                                                id="outlined-adornment-amount"
                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                label="Total"
                                                                sx={{
                                                                    '& input[type="text"]': {
                                                                        textAlign: 'right', // Align text to the right
                                                                    },
                                                                }}
                                                                value={detail.total}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6} lg={2} xl={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="outlined-adornment-amount">Total FF</InputLabel>
                                                            <OutlinedInput
                                                                disabled
                                                                size="small"
                                                                id="outlined-adornment-amount"
                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                                label="Total FF"
                                                                sx={{
                                                                    '& input[type="text"]': {
                                                                        textAlign: 'right', // Align text to the right
                                                                    },
                                                                }}
                                                                value={detail.total_ff}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            }
                                            
                                            {
                                                (type === 'outbound' && detail.name === 'Gasoil')
                                                ?
                                                <>
                                                    <Grid item xs={12} lg={6} xl={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            label='Observaciones'
                                                            value={detail.notes}
                                                            onChange={(e)=>handleUpdateItemDetail(index,null,null,null,null,null,e.target.value,null)}
                                                        >
                                                            <MenuItem value='Consumo interno'>Consumo interno</MenuItem>
                                                            <MenuItem value='Consumo interno'>Ayuda</MenuItem>
                                                            <MenuItem value='Consumo interno'>Venta</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} xl={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            label='Chofer'
                                                            value={detail.driver}
                                                            onChange={(e)=>handleUpdateItemDetail(index,null,null,null,null,null,null,e.target.value)}
                                                        >
                                                            <MenuItem value={0}>Selecciona una opción</MenuItem>
                                                            {
                                                                employees.length !== 0 &&
                                                                employees.map(item=>
                                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </TextField>
                                                    </Grid>
                                                </>
                                                :
                                                <Grid item xs={6} lg={6} xl={6}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label='Observaciones'
                                                        value={detail.notes}
                                                        onChange={(e)=>handleUpdateItemDetail(index,null,null,null,null,null,e.target.value,null)}
                                                    />
                                                </Grid>
                                            }
                                            
                                            <Grid item xs={6} lg={2} xl={1} justifyContent={'flex-end'}>
                                                <Button fullWidth size="small" color="error" onClick={()=>handleRemoveItemDetail(index)} sx={{mb:2}}>
                                                    <Typography>
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                        &nbsp; Eliminar
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Button onClick={()=>handleAddItemDetail()}>
                                            <Typography>
                                                <FontAwesomeIcon icon={faPlus}/>
                                                &nbsp;Agregar Detalle
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {/**Para a futuro agregar campos de descuento etc */}
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Grid>

                        {/**Totales */}
                        {
                            userData.user.type === 1 &&
                            <Grid container spacing={3} justifyContent={'flex-end'}>
                                <Grid item lg={4} xl={3}>
                                    <Grid container spacing={3} sx={{pl:3,pr:1}}>
                                        <Grid item lg={6} xl={6}>
                                            <Typography textAlign={'end'} fontWeight={'bold'}>Total</Typography>
                                        </Grid>
                                        <Grid item lg={6} xl={6}>
                                            <Typography textAlign={'end'} fontWeight={'bold'}>{parseFloat(total).toFixed(2)}</Typography>
                                        </Grid>

                                        <Grid item lg={6} xl={6}>
                                            <Typography textAlign={'end'} fontWeight={'bold'}>Total FF</Typography>
                                        </Grid>
                                        <Grid item lg={6} xl={6}>
                                            <Typography textAlign={'end'} fontWeight={'bold'}>{parseFloat(totalFF).toFixed(2)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        
                    </Paper>
                </Grid>
                <Grid item xl={2}>
                    <Button fullWidth variant="contained" onClick={()=>handleSubmit()}>
                        <Typography>Crear Orden</Typography>
                    </Button>
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={success === true ? 'Finalizar' : 'Cerrar'}
                onClick={()=>handleClose()}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />
        </Container>
    )
}