import { Backdrop, Box, CircularProgress } from "@mui/material";
import Lottie from "lottie-react";

import qud_logo from '../assets/images/logos/QUD212121.png';
import loaderAnimation from '../assets/lottie/loader.json';

/**
 * 
 * @param {boolean} open 
 * @returns 
 */
export default function QUDProgress(props)
{
    
    return(
        <Backdrop
            sx={{ bgcolor:'#00000040', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        >
            <Box sx={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                <img width={100} src={qud_logo} alt="QUD"/>
                <Lottie
                    autoPlay
                    style={{
                        width: 140,
                        height: 140,
                    }}
                    animationData={loaderAnimation}
                />
            </Box>
        </Backdrop>
    )
}
