
import AuthRoutes from "../../apiRoutes/AuthRoutes";
import CustomerRoutes from "../../apiRoutes/CustomerRoutes";
import WebsiteRoutes from "../../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function toCompleteProfile(uuid)
{
    let response = await GET(AuthRoutes.toCompleteProfile+uuid);
    return response;
}

export async function completeProfile(uuid,name,lastName,phone,password)
{
    const params = [
        {name:'uuid',value:uuid},
        {name:'name',value:name},
        {name:'apellido',value:lastName},
        {name:'telefono',value:phone},
        {name:'password',value:password}
    ];

    let response = await POST(AuthRoutes.completeProfile,params);
    return response;
}


export async function getProfile()
{
    let response = await GET(CustomerRoutes.Profile.Index);
    return response;
}

export async function updateProfile(name,lastName,documentType,document,prefix,phoneNumber)
{
    const params = [
        {name:'name',value:name},
        {name:'lastName',value:lastName},
        {name:'documentType',value:documentType},
        {name:'document',value:document},
        {name:'prefix',value:prefix},
        {name:'phoneNumber',value:phoneNumber}
    ];

    let response = await POST(CustomerRoutes.Profile.Update,params);
    return response;
}

export async function updateProfileImage(profileImage)
{
    const params = [
        {name:'profileImage',value:profileImage}
    ];
    let response = await POST(CustomerRoutes.Profile.UpdateImages,params);
    

    return response;
}