import { useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileInvoice, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function CavaDataTable({records,onClickDetails})
{
    const columns = [
        {
            name:'id',
            label:<Typography>ID</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'created_by',
            label:<Typography>Creado Por</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'customer',
            label:<Typography>Cliente</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'cattle',
            label:<Typography>Reses</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'total_tickets_weight',
            label:<Typography>Peso en Tickets</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'total_input_weight',
            label:<Typography>Peso Recibido</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'total_output_weight',
            label:<Typography>Peso Despachado</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'remaining_cattle',
            label:<Typography>Reses en Cava</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Actualizar'>
                                <IconButton onClick={()=> onClickDetails(records[dataIndex])}>
                                    <FontAwesomeIcon icon={faEye}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title={<Typography>Registro de Reses</Typography>}
            data={records}
            columns={columns}
            options={options}
        />
    )
}