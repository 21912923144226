import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import BranchContext from "../../../navigation/context/branchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllExpenses } from "../../../controllers/admin/ExpensesController";
import ExpensesDataTable from "./dataTable";
import { PieChart } from "@mui/x-charts";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import QUDProgress from "../../../components/progress";

export default function Expenses()
{
    const { selectedBranch } = useContext(BranchContext);
    const [expenses,setExpenses] = useState([]);
    const [total,setTotal] = useState(0);
    const [expensesByType,setExpensesByType] = useState([]);

    const [startDate,setStartDate] = useState(moment().startOf('month'));
    const [endDate,setEndDate] = useState(moment().add(1, 'month').startOf('month'));

    const [isLoading,setIsLoading] = useState(false);


    const history = useHistory();


    useEffect(()=>{
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetExpenses(_startDate,_endDate);
    },[])

    const handleGetExpenses = async (_startDate,_endDate) => {
        setIsLoading(true);
        let response = await getAllExpenses(selectedBranch.id,_startDate,_endDate);
        if(response.success === true)
        {
            setExpenses(response.data.expenses);
            setTotal(response.data.total);
            setExpensesByType(response.data.expensesByType);
        }
        setIsLoading(false);
    }

    const redirect = (route) => {
        history.push(route)
    }

    const handleSearch = async () => {
        let _startDate = moment(startDate).format('YYYY-MM-DD');
        let _endDate = moment(endDate).format('YYYY-MM-DD');
        handleGetExpenses(_startDate,_endDate);
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Gastos`}]}/>
            <SectionTitle title={'Gastos'}/>

            <Grid container spacing={3} mb={3} justifyContent={'center'}>
                <Grid item lg={6} xl={6}>
                    <Grid container spacing={3}>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha inicial"
                                        //minDate={moment.now()}
                                        
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} xl={5}>
                            {/**Fecha */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label="Fecha final"
                                        //minDate={moment.now()}
                                        
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue) }
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        minDate={new Date('2024-01-01')}
                                        //maxDate={new Date()}    
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} xl={2}>
                            <Button
                                fullWidth 
                                sx={{height:'100%'}}
                                variant="contained"
                                onClick={()=>handleSearch()}
                            >
                                <FontAwesomeIcon size="2x" icon={faSearch}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Button
                variant='contained'
                endIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                onClick={()=>redirect('/expenses/create')}
            >
                <Typography>Agregar Gasto</Typography>
            </Button>
            <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={2} xl={2} justifyContent={'center'}>
                                <Typography variant="h6" fontWeight={'bold'}>Total</Typography>
                                <Typography variant="h4" fontWeight={'bold'}>
                                    {total} $
                                </Typography>
                            </Grid>
                            
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3}>
                                    {
                                        expensesByType.length !== 0 &&
                                        expensesByType.map(item=>
                                            <Grid item lg={2} xl={2}>
                                                <Typography variant="body1" fontWeight={'bold'}>{item.label}</Typography>
                                                <Typography variant="h6" fontWeight={'bold'}>{item.value}</Typography>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid item lg={4} xl={4}>
                                {
                                    expensesByType.length !== 0 &&
                                    <PieChart
                                        series={[
                                            {
                                                data: expensesByType
                                            }
                                        ]}
                                        width={400}
                                        height={200}
                                    />
                                }
                                
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        expenses.length !== 0 &&
                        <ExpensesDataTable
                            expenses={expenses}
                            onDelete={handleGetExpenses}
                        />
                    }
                </Grid>
            </Grid>

            <QUDProgress open={isLoading}/>
        </Container>
    )
}