import { forwardRef, useContext, useEffect, useReducer, useState } from "react";
import { Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Snackbar, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import BranchContext from "../../../navigation/context/branchContext";
import AddCava from "./add";
import { getCavaDetails, getCavaRecords, updateDetailByID } from "../../../controllers/admin/CavaController";
import CavaDataTable from "./dataTable";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    isLoading : false,
    open: false,
    title: '',
    message: '',
    success: false
};

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default function Cava(){
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);

    const [records,setRecords] = useState([]);
    const [record,setRecord] = useState([]);
    const [details,setDetails] = useState([]);
    const [openModal,setOpenModal] = useState(false);
    const [date,setDate] = useState(new moment().format());
    const [openSnack,setOpenSnack] = useState(false);

    const [state,setState] = useReducer(reducer,initialState);
    const {
        isLoading,
        open,
        title,
        message,
        success
    } = state

    useEffect(()=>{
        handleGetRecords();
    },[]);

    const handleGetRecords = async () => {
        const response = await getCavaRecords();
        if(response.success === true){
            setRecords(response.data.records);
        }
    }

    const handleOnClickDetails = async (record) => {
        const response = await getCavaDetails(record.id);
        if(response.success === true)
        {
            setRecord(record);
            setDetails(response.data.details);
            setOpenModal(true);
        }
    }

    const handleClose = () => {
        setOpenModal(false);
        setState({field:'open',value:false});
        handleGetRecords();
    };

    const handleInputChange = (index, field, value) => {
        setDetails(prevDetails => {
            const updatedDetails = [...prevDetails]; // Crear una copia del array de objetos
            updatedDetails[index][field] = value; // Actualizar el campo específico del objeto en el índice dado
            return updatedDetails; // Retornar el nuevo array de objetos actualizado
        });
    };

    const handleUpdateDetailByID = async (index) => {
        const detailObj = details[index];
        console.log('el array a enviar es ===> ',detailObj);
        let response = await updateDetailByID(detailObj.id,detailObj);
        if(response.success === true)
        {
            setState({field:'message',value:`${response.message}`});
            setState({field:'success',value:true});
            setOpenSnack(true);
        }
        else
        {
            setState({field:'message',value:`${response.message}`});
            setState({field:'success',value:false});
            setOpenSnack(true);
        }
    }   

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`Cava`}]}/>
            <SectionTitle title={'Cava'}/>
            <Grid container spacing={3}>
                <Grid item lg={12}>
                    <AddCava/>
                </Grid>
                
                <Grid item lg={12}>
                    <CavaDataTable records={records} onClickDetails={handleOnClickDetails}/>
                </Grid>
            </Grid>

            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                maxWidth='xl'
                fullWidth
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Detalles de Registro"}</DialogTitle>
                <DialogContent>
                    {
                        details.length !== 0 &&
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography>Creado por: {record.created_by}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Cliente: {record.customer}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography>Reses: {record.cattle}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>P.Tickets: {record.total_tickets_weight}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>P. Recibido: {record.total_input_weight}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>P. Despachado: {record.total_output_weight}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>R. Restantes: {record.remaining_cattle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>

                            <Grid item xs={12}>
                                
                                {      
                                    details.map((detail, index,) => 
                                        <Grid container spacing={3} mt={1}>
                                            <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                                                <TextField
                                                    fullWidth
                                                    label={`Cava #`}
                                                    value={detail.cava_number}
                                                    onChange={(e) =>
                                                        handleInputChange(index,'cava_number', e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                                                <TextField
                                                    fullWidth
                                                    label={`Peso Ticket`}
                                                    value={detail.ticket_weight || ""} // Asegura que siempre haya un valor
                                                    onChange={(e) => handleInputChange(index,'ticket_weight', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                                                <TextField
                                                    fullWidth
                                                    label={`Peso Recibido`}
                                                    value={detail.input_weight || ""} // Asegura que siempre haya un valor
                                                    onChange={(e) => handleInputChange(index,'input_weight', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Fecha Recibido"
                                                        value={detail.input_date}
                                                        id="input_date"
                                                        name="input_date"
                                                        maxDate={moment().format()}
                                                        onChange={(newValue) => handleInputChange(index,'input_date', newValue) }
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                                                <TextField
                                                    fullWidth
                                                    label={`Peso Despacho`}
                                                    value={detail.output_weight || ""} // Asegura que siempre haya un valor
                                                    onChange={(e) => handleInputChange(index,'output_weight', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DatePicker
                                                        label="Fecha Despacho"
                                                        value={detail.output_date || new moment().format()}
                                                        id="output_date"
                                                        name="output_date"
                                                        maxDate={moment().format()}
                                                        onChange={(newValue) => handleInputChange(index,'output_date', newValue) }
                                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <IconButton onClick={()=>handleUpdateDetailByID(index)}>
                                                        <FontAwesomeIcon icon={faPenToSquare}/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Divider/>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained">
                        <Typography fontWeight={'bold'}>Actualizar Todos los registros</Typography>
                    </Button>
                    
                </DialogActions>
            </Dialog>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={success === true ? 'Finalizar' : 'Cerrar'}
                onClick={()=>handleClose()}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />

            <Snackbar open={openSnack} TransitionComponent={Transition} anchorOrigin={{vertical:'top',horizontal:'right'}} autoHideDuration={6000} onClose={()=>setOpenSnack(false)}>
                <Alert
                    onClose={()=>setOpenSnack(false)}
                    severity={success?"success":"error"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    This is a success Alert inside a Snackbar!
                </Alert>
            </Snackbar>
        </Container>
    )
}