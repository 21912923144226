import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faFileInvoice, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';

//export default class TablaProductos extends Component
export default function OrderDetailsDataTable(props)
{
    const {userData,setUserData} = useContext(UserContext)
    const [details,setDetails] = useState(props.details);

    const detailsAux = [
        {uuid:'XYZ',producto:'Nombre Producto',quantity:1,price:3.00},
        {uuid:'XYZ',producto:'Nombre Producto',quantity:1,price:3.00}
    ];




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <TableContainer component={Paper} elevation={3}>
            <Table sx={{minWidth:700}}>
                <TableHead>
                    <TableRow>
                        <TableCell width={'30%'}><Typography fontWeight={'bold'}>Item</Typography></TableCell>
                        <TableCell align='right'><Typography fontWeight={'bold'}>Cantidad</Typography></TableCell>
                        <TableCell align='right'><Typography fontWeight={'bold'}>Precio</Typography></TableCell>
                        <TableCell align='right'><Typography fontWeight={'bold'}>Monto</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        details.map(item=>
                            <TableRow>
                                <TableCell width={'30%'}>
                                    <Box>
                                        <Typography variant='h6'>{item.producto}</Typography>
                                        <Typography variant='body1'>{item.descripcion}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align='right'><Typography variant='h6'>{parseFloat(item.cantidad).toFixed(2)}</Typography></TableCell>
                                <TableCell align='right'><Typography variant='h6'>{parseFloat(item.precio).toFixed(2)}</Typography></TableCell>
                                <TableCell align='right'><Typography variant='h6'>{parseFloat(item.cantidad*item.precio).toFixed(2)}</Typography></TableCell>
                            </TableRow>
                        )
                    }
                    <TableRow>
                        <TableCell colSpan={3} align='right'><Typography fontWeight={'bold'} variant='h6'>Total</Typography></TableCell>
                        <TableCell align='right'><Typography fontWeight={'bold'} variant='h6'>{parseFloat(props.total).toFixed(2)}</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}