const URLMorocotasBase = 'https://api.morocotaspayments.com/'; //URLBase + 'morocotas/public/';

const v1 = 'v1/';
const v2 = 'v2/';

const urlMorocotasAPI = URLMorocotasBase + 'api/';

const MorocotasRoutes = {
    BASE : URLMorocotasBase,
    IMG : URLMorocotasBase + 'img/',
    AUTH : 
    {
        signup : urlMorocotasAPI + v1 + 'auth/signup',
        login : urlMorocotasAPI + v1 + 'auth/login',
        ResetPassword : 
        {
            email : urlMorocotasAPI + v1 + 'auth/password/email',
            verify : urlMorocotasAPI + v1 + 'auth/password/verify',
            reset : urlMorocotasAPI + v1 + 'auth/password/reset',
        },
        verify : urlMorocotasAPI + v1 + 'auth/register/verify/' //completar con email
        

    },
    USER : 
    {
        user : urlMorocotasAPI + v1 + 'user/'
    },
    TRANSACTIONS : 
    {
        user : urlMorocotasAPI + v1 + 'user/transactions',
        deposit : urlMorocotasAPI + v2 + 'transaction/depositar',
        pay : urlMorocotasAPI + v2 + 'transaction/pagar'
    },
    CRYPTOCURRENCIES : 
    {
        addresses : urlMorocotasAPI + v1 + 'criptomonedas/addresses',
    },
    BANKS : 
    {
        accountNumbers : urlMorocotasAPI + v2 + 'bank/listacurrency/', //completar con el id de la moneda
    },
    EXCHANGE_RATE : 
    {
        ved_usd : urlMorocotasAPI + v2 + 'exchange_rate/BCV/USD'
    }

    
    /*Base : URLMorocotasBase,
    Login : URLMorocotas + 'login',
    Depositos : 
    {
        VES_ParaAgregar : urlMorocotasDepositos + 'ves/agregar', //GET
        Agregar : urlMorocotasDepositos + 'agregar', //POST
    },
    Criptomonedas : 
    {
        Addresses : URLMorocotas + 'criptomonedas/addresses',
        
    },
    Datos : 
    {
        all : URLMorocotas + 'transacciones/ves/datos',
    },
    Pagar : URLMorocotas + 'transacciones/pagarqud',
    PagarUsuario : URLMorocotas + 'transacciones/pagar',
    TasaCambio : URLMorocotas + 'tasacambio/v2/' */

};


export {MorocotasRoutes}