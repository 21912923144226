import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST } from "../ConsumeApiController";



export async function getAllproducts(branch_id)
{
    let response = await GET(AdminRoutes.Products.GetAll+branch_id);
    return response;
}

export async function get_data_to_store(branch_id,product_id)
{
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'product_id',value:product_id}
    ];

    let response = await POST(AdminRoutes.Products.GetDataToStore,params);
    
    return response;
}


export async function create_product(branch_id,name,description,stock,category_id,unit_price,ff_price,measurement_unit_id)
{
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'name',value:name},
        {name:'description',value:description},
        {name:'stock',value:stock},
        {name:'category_id',value:category_id},
        {name:'unit_price',value:unit_price},
        {name:'ff_price',value:ff_price},
        {name:'measurement_unit_id',value:measurement_unit_id}
    ];

    let response = await POST(AdminRoutes.Products.Create,params);
    return response;
}


export async function update_product(product_id,description,category_id,unit_price,ff_price,measurement_unit_id)
{
    const params = [
        {name:'product_id',value:product_id},
        {name:'description',value:description},
        {name:'category_id',value:category_id},
        {name:'unit_price',value:unit_price},
        {name:'ff_price',value:ff_price},
        {name:'measurement_unit_id',value:measurement_unit_id}
    ];

    let response = await POST(AdminRoutes.Products.Update,params);
    return response;
}


export async function getProductDetailsById(product_id,start_date,end_date)
{
    const params = [
        {name:'product_id',value:product_id},
        {name:'start_date',value:start_date},
        {name:'end_date',value:end_date}
    ];

    let response = await POST(AdminRoutes.Products.Details,params);
    return response;
}
