import { useContext, useEffect, useReducer } from "react";
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Snackbar, TextField, Typography } from "@mui/material";

import { AddCircle, Edit, Facebook, Instagram, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import { LoadingButton } from "@mui/lab";
import { AddHorario, GetHorarios, UpdateHorario } from "../../../controllers/company/CompanyProfileController";
import UserContext from "../../../navigation/context/userContext";



//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {
    horarios: [],

    uuid:'',
    dia: '',        errorDia: false,
    inicio:'',      errorInicio: false,
    fin:'',         errorFin: false,
    estatus:'',     errorEstatus: false,

    operacion:'agregar',
    
    isLoading : false,

    open: false,

    openAlert: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }


export default function WorkingHours()
{
    const {userData,setUserData} = useContext(UserContext);
    const [state,setState] = useReducer(reducer,initialState);

    const {
        horarios,

        uuid,
        dia, errorDia, 
        inicio, errorInicio, 
        fin, errorFin, 
        estatus, errorEstatus,

        operacion,

        isLoading,
        open,

        openAlert,
        title,
        message,
        success

    } = state;

    useEffect(()=>{
        handleGetHorarios();
    },[])

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleGetHorarios = async () => {
        let response = await GetHorarios();
        if(response.success === true)
        {
            setState({field:'horarios',value:response.data.horarios})
        }
    }

    const cancel = () => {
        setState({field:'dia',value:''});
        setState({field:'inicio',value:''});
        setState({field:'fin',value:''});
        setState({field:'estatus',value:''});

        setState({field:'errorDia',value:false});
        setState({field:'errorInicio',value:false});
        setState({field:'errorFin',value:false});
        setState({field:'errorEstatus',value:false});
    }

    const handleOpenAdd = () => {
        setState({field:'operacion',value:'agregar'});

        cancel();

        setState({field:'open',value:true});
    }

    const handleOpenUpdate = (item) => {
        setState({field:'operacion',value:'actualizar'});

        setState({field:'uuid',value:item.uuid});
        setState({field:'dia',value:item.dia});
        setState({field:'inicio',value:item.inicio});
        setState({field:'fin',value:item.fin});
        setState({field:'estatus',value:item.estatus});

        setState({field:'open',value:true});
    }

    const handleClose = () => {
        cancel();
        setState({field:'open',value:false})
    }

    const formVerification = () => {
        var ban = 0;

        if(dia === '')
        {
            setState({field:'errorDia',value:true});
            ban = 1;
        }

        if(inicio === '')
        {
            setState({field:'errorInicio',value:true});
            ban = 1;
        }
        
        if(fin === '')
        {
            setState({field:'errorFin',value:true});
            ban = 1;
        }

        if(estatus === '')
        {
            setState({field:'errorEstatus',value:true});
            ban = 1;
        }
        
        return ban;
    }
    

    const handleAddSubmit = async () => {
        
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value:true});

            if(operacion === 'agregar')
            {
                let response = await AddHorario(dia,inicio,fin,estatus);
                if(response.success === true)
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:true});
                    setState({field:'openAlert',value:true});
                }
                else
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:true});
                }
            }
            else
            {
                let response = await UpdateHorario(uuid,dia,inicio,fin,estatus);
                if(response.success === true)
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:true});
                    setState({field:'openAlert',value:true});
                }
                else
                {
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:true});
                }
            }

            setState({field:'isLoading',value:false});
            handleClose();
            handleGetHorarios()
        }
    }

    return(
        <Paper>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography>Horarios de Trabajo</Typography>
                <IconButton size='large' color='primary' onClick={()=>handleOpenAdd()}>
                    <AddCircle/>
                </IconButton>
            </div>

            <List>
                {
                    horarios.lenght !== 0
                    ?
                    horarios.map((item)=>
                        <ListItem
                            key={item.uuid}
                            secondaryAction={
                                <IconButton edge='end' onClick={()=>handleOpenUpdate(item)}>
                                    <Edit/>
                                </IconButton>
                            }
                        >
                            
                            <ListItemText id={'labelId'+item.uuid} 
                                primary={<Typography noWrap={true}>{item.dia_string}</Typography>} 
                                secondary={item.inicio+' - '+item.fin}
                            />
                        </ListItem>
                    )
                    :null
                }
            </List>


            <Dialog
                open={open}
            >
                <DialogTitle>
                    {operacion === 'agregar' ? 'Agregar' : 'Actualizar'} Horario de Trabajo
                </DialogTitle>
                <DialogContent>
                    <br/>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="dia"
                            name='dia'
                            label="Día"
                            variant="outlined"
                            value={dia}
                            onChange = { onChange }
                            onBlur={ () => (dia === '' ? setState({field:'errorDia',value:true}) : setState({field:'errorDia',value:false}) ) }
                            error = {errorDia}
                            helperText='Debe seleccionar un día'
                        >
                            <MenuItem value=''>Seleccione el día</MenuItem>
                            <MenuItem value='0'>Domingo</MenuItem>
                            <MenuItem value='1'>Lunes</MenuItem>
                            <MenuItem value='2'>Martes</MenuItem>
                            <MenuItem value='3'>Miercoles</MenuItem>
                            <MenuItem value='4'>Jueves</MenuItem>
                            <MenuItem value='5'>Viernes</MenuItem>
                            <MenuItem value='6'>Sabado</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="inicio"
                            name='inicio'
                            label="Hora de inicio"
                            variant="outlined"
                            value={inicio}
                            onChange = { onChange }
                            onBlur={ () => (inicio === '' ? setState({field:'errorInicio',value:true}) : setState({field:'errorInicio',value:false}) ) }
                            error = {errorInicio}
                            helperText='Debe seleccionar la hora de inicio'
                        >
                            <MenuItem value=''>Seleccione la hora de inicio</MenuItem>
                            <MenuItem value='00:00'>00:00</MenuItem>
                            <MenuItem value='00:30'>00:30</MenuItem>
                            <MenuItem value='01:00'>01:00</MenuItem>
                            <MenuItem value='01:30'>01:30</MenuItem>
                            <MenuItem value='02:00'>02:00</MenuItem>
                            <MenuItem value='02:30'>02:30</MenuItem>
                            <MenuItem value='03:00'>03:00</MenuItem>
                            <MenuItem value='03:30'>03:30</MenuItem>
                            <MenuItem value='04:00'>04:00</MenuItem>
                            <MenuItem value='04:30'>04:30</MenuItem>
                            <MenuItem value='05:00'>05:00</MenuItem>
                            <MenuItem value='05:30'>05:30</MenuItem>
                            <MenuItem value='06:00'>06:00</MenuItem>
                            <MenuItem value='06:30'>06:30</MenuItem>
                            <MenuItem value='07:00'>07:00</MenuItem>
                            <MenuItem value='07:30'>07:30</MenuItem>
                            <MenuItem value='08:00'>08:00</MenuItem>
                            <MenuItem value='08:30'>08:30</MenuItem>
                            <MenuItem value='09:00'>09:00</MenuItem>
                            <MenuItem value='09:30'>09:30</MenuItem>
                            <MenuItem value='10:00'>10:00</MenuItem>
                            <MenuItem value='10:30'>10:30</MenuItem>
                            <MenuItem value='11:00'>11:00</MenuItem>
                            <MenuItem value='11:30'>11:30</MenuItem>
                            <MenuItem value='12:00'>12:00</MenuItem>
                            <MenuItem value='12:30'>12:30</MenuItem>
                            <MenuItem value='13:00'>13:00</MenuItem>
                            <MenuItem value='13:30'>13:30</MenuItem>
                            <MenuItem value='14:00'>14:00</MenuItem>
                            <MenuItem value='14:30'>14:30</MenuItem>
                            <MenuItem value='15:00'>15:00</MenuItem>
                            <MenuItem value='15:30'>15:30</MenuItem>
                            <MenuItem value='16:00'>16:00</MenuItem>
                            <MenuItem value='16:30'>16:30</MenuItem>
                            <MenuItem value='17:00'>17:00</MenuItem>
                            <MenuItem value='17:30'>17:30</MenuItem>
                            <MenuItem value='18:00'>18:00</MenuItem>
                            <MenuItem value='18:30'>18:30</MenuItem>
                            <MenuItem value='19:00'>19:00</MenuItem>
                            <MenuItem value='19:30'>19:30</MenuItem>
                            <MenuItem value='20:00'>20:00</MenuItem>
                            <MenuItem value='20:30'>20:30</MenuItem>
                            <MenuItem value='21:00'>21:00</MenuItem>
                            <MenuItem value='21:30'>21:30</MenuItem>
                            <MenuItem value='22:00'>22:00</MenuItem>
                            <MenuItem value='22:30'>22:30</MenuItem>
                            <MenuItem value='23:00'>23:00</MenuItem>
                            <MenuItem value='23:30'>23:30</MenuItem>
                        </TextField>
                    </FormControl>
                    
                    {/**Hora fin */}
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="fin"
                            name='fin'
                            label="Hora de fin"
                            variant="outlined"
                            value={fin}
                            onChange = { onChange }
                            onBlur={ () => (fin === '' ? setState({field:'errorFin',value:true}) : setState({field:'errorFin',value:false}) ) }
                            error = {errorFin}
                            helperText='Debe seleccionar la hora de fin'
                        >
                            <MenuItem value=''>Seleccione la hora de fin</MenuItem>
                            <MenuItem value='00:00'>00:00</MenuItem>
                            <MenuItem value='00:30'>00:30</MenuItem>
                            <MenuItem value='01:00'>01:00</MenuItem>
                            <MenuItem value='01:30'>01:30</MenuItem>
                            <MenuItem value='02:00'>02:00</MenuItem>
                            <MenuItem value='02:30'>02:30</MenuItem>
                            <MenuItem value='03:00'>03:00</MenuItem>
                            <MenuItem value='03:30'>03:30</MenuItem>
                            <MenuItem value='04:00'>04:00</MenuItem>
                            <MenuItem value='04:30'>04:30</MenuItem>
                            <MenuItem value='05:00'>05:00</MenuItem>
                            <MenuItem value='05:30'>05:30</MenuItem>
                            <MenuItem value='06:00'>06:00</MenuItem>
                            <MenuItem value='06:30'>06:30</MenuItem>
                            <MenuItem value='07:00'>07:00</MenuItem>
                            <MenuItem value='07:30'>07:30</MenuItem>
                            <MenuItem value='08:00'>08:00</MenuItem>
                            <MenuItem value='08:30'>08:30</MenuItem>
                            <MenuItem value='09:00'>09:00</MenuItem>
                            <MenuItem value='09:30'>09:30</MenuItem>
                            <MenuItem value='10:00'>10:00</MenuItem>
                            <MenuItem value='10:30'>10:30</MenuItem>
                            <MenuItem value='11:00'>11:00</MenuItem>
                            <MenuItem value='11:30'>11:30</MenuItem>
                            <MenuItem value='12:00'>12:00</MenuItem>
                            <MenuItem value='12:30'>12:30</MenuItem>
                            <MenuItem value='13:00'>13:00</MenuItem>
                            <MenuItem value='13:30'>13:30</MenuItem>
                            <MenuItem value='14:00'>14:00</MenuItem>
                            <MenuItem value='14:30'>14:30</MenuItem>
                            <MenuItem value='15:00'>15:00</MenuItem>
                            <MenuItem value='15:30'>15:30</MenuItem>
                            <MenuItem value='16:00'>16:00</MenuItem>
                            <MenuItem value='16:30'>16:30</MenuItem>
                            <MenuItem value='17:00'>17:00</MenuItem>
                            <MenuItem value='17:30'>17:30</MenuItem>
                            <MenuItem value='18:00'>18:00</MenuItem>
                            <MenuItem value='18:30'>18:30</MenuItem>
                            <MenuItem value='19:00'>19:00</MenuItem>
                            <MenuItem value='19:30'>19:30</MenuItem>
                            <MenuItem value='20:00'>20:00</MenuItem>
                            <MenuItem value='20:30'>20:30</MenuItem>
                            <MenuItem value='21:00'>21:00</MenuItem>
                            <MenuItem value='21:30'>21:30</MenuItem>
                            <MenuItem value='22:00'>22:00</MenuItem>
                            <MenuItem value='22:30'>22:30</MenuItem>
                            <MenuItem value='23:00'>23:00</MenuItem>
                            <MenuItem value='23:30'>23:30</MenuItem>
                        </TextField>
                    </FormControl>

                    <FormControl fullWidth style={{marginTop:7}}>
                        <TextField
                            fullWidth
                            margin="normal"
                            select
                            id="estatus"
                            name='estatus'
                            label="Estatus"
                            variant="outlined"
                            value={estatus}
                            onChange = { onChange }
                            onBlur={ () => (estatus === '' ? setState({field:'errorEstatus',value:true}) : setState({field:'errorEstatus',value:false}) ) }
                            error = {errorEstatus}
                            helperText={errorEstatus && 'Debe ingresar seleccionar el estatus'}
                        >
                            <MenuItem value='1'>Activo</MenuItem>
                            <MenuItem value='0'>Inactivo</MenuItem>
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={()=>handleClose()}>Cancelar</Button>
                    <LoadingButton
                        loading={isLoading}
                        onClick={()=>handleAddSubmit()}
                    >
                        Aceptar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={()=>{
                    setState({field:'success',value:false});
                    setState({field:'openAlert',value:false});
                }}
                autoHideDuration={6000}
            >
                <Alert severity={success === true ? 'success' : 'error'}>
                    <AlertTitle>
                        {
                            success === true
                            ? 'Operacion Exitosa!'
                            : 'Error!'
                        }
                    </AlertTitle>
                    {message}
                </Alert>
            </Snackbar>
        </Paper>
    )
}