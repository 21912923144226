import React, { useContext, useEffect, useReducer, useState } from "react";
import { Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomBreadcrumbs from "../../../components/breadcrumbs";

import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { AddProduct, GetForAdd } from "../../../controllers/company/ProductsController";
import { useHistory } from "react-router-dom";
import ModalDialog from "../../../components/modalDialog";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import { create_product, get_data_to_store } from "../../../controllers/admin/ProductsController";
import BranchContext from "../../../navigation/context/branchContext";






//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    measurementUnits:[],
    categories:[],

    nombre : '',          errorNombre : false,
    descripcion : '',     errorDescripcion : false,
    idUnidad : '',        errorIdUnidad : false,
    idSubCategoria : '',  errorIdSubCategoria : false,
    facturacion: '',      errorFacturacion: false,
    precio : '',          errorPrecio : false,
    precioFF : '',        errorPrecioFF : false,
    stock:'',
    estatus : '',         errorEstatus : false,
    
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }

  


export default function ProductsCreate()
{
    const { selectedBranch } = useContext(BranchContext);
    const [state,setState] = useReducer(reducer,initialState);
    const [img,setImg] = useState([]);

    const {
        measurementUnits, categories,
        nombre, errorNombre, descripcion, errorDescripcion,
        idUnidad, errorIdUnidad, idSubCategoria, errorIdSubCategoria,
        precio, errorPrecio, precioFF, errorPrecioFF, stock, estatus, errorEstatus,

        isLoading,
        open,
        title,
        message,
        success

    } = state;

    useEffect(()=>{
        getForAdd();
    },[]);

    const history = useHistory();


    const getForAdd = async () => {
        let response = await get_data_to_store(selectedBranch.id,null);
        if(response.success === true)
        {
            setState({field:'measurementUnits',value:response.data.measurement_units});
            setState({field:'categories',value:response.data.categories});
        }
    }



    
    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }
        
            
        if(idUnidad === '')
        {
            setState({field:'errorIdUnidad',value:true});
            ban = 1;
        }

        if(idSubCategoria === '')
        {
            setState({field:'errorIdSubCategoria',value:true});
            ban = 1;
        }

        if(precio === '')
        {
            setState({field:'errorPrecio',value:true});
            ban = 1;
        }

        if(precioFF === '')
        {
            setState({field:'errorPrecioFF',value:true});
            ban = 1;
        }
        
        return ban;
    }
    
    
    const handleAddProduct = async () => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            let response = await create_product(selectedBranch.id,nombre,descripcion,stock,idSubCategoria,precio,precioFF,idUnidad);
            if(response.success === true)
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:`${response.message}. Desea agregar otro producto?`});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
        }
        setState({field:'isLoading',value:false});
    }

    const clear = () => {
        setState({field:'nombre',value:''}); setState({field:'errorNombre',value:false});
        setState({field:'descripcion',value:''}); setState({field:'errorDescripcion',value:false});
        setState({field:'idUnidad',value:''}); setState({field:'errorIdUnidad',value:false});
        setState({field:'idSubCategoria',value:''}); setState({field:'errorIdSubCategoria',value:false});
        setState({field:'precio',value:''}); setState({field:'errorPrecio',value:false});
        setState({field:'estatus',value:''}); setState({field:'errorEstatus',value:false});

        setState({field:'suscripcion',value:'0'}); setState({field:'facturacion',value:''});



        setState({field:'open',value:false});
    }


    const handleClose = () => {
        if(success === true)
            clear();
        else
            setState({field:'open',value:'false'})
    }



    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/products'
                crumbs={[
                    {name:'Productos',link:'/products'},
                    {name:'Agregar',link:'/products/add'}
                ]}
            />
            <br/>
            <Typography variant='h4' component='h4' sx={{textAlign:'center'}}>
                Agregar producto
            </Typography>
            <br/>
            <Grid container spacing={3} justifyContent={'center'}>

                {/**Nombre - Descripcion - Unidad de medida - Categoria - Precio - Estatus */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Nombre */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Nombre'
                                name='nombre'
                                id='nombre'
                                required
                                value={nombre}
                                onChange = { onChange }
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del producto'}
                            />
                        </Grid>

                        {/**Descripcion */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label='Descripción'
                                name='descripcion'
                                id='descripcion'
                                required
                                value={descripcion}
                                onChange = { onChange }
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripción del producto'}
                            />
                        </Grid>

                        {/**Unidad de medida */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                select
                                id="idUnidad"
                                name='idUnidad'
                                label="Unidad de medida"
                                variant="outlined"
                                value = {idUnidad}
                                onChange = { onChange }
                                onBlur={ () => (idUnidad === '' ? setState({field:'errorIdUnidad',value:true}) : setState({field:'errorIdUnidad',value:false}) ) }
                                error = {errorIdUnidad}
                                helperText={errorIdUnidad && 'Seleccione la unidad de medida'}
                            >
                                <MenuItem value=''>Seleccione una unidad</MenuItem>
                                {
                                    measurementUnits.length !== 0 &&
                                    measurementUnits.map((item)=>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>

                        {/**Categorias */}
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                select
                                id="idSubCategoria"
                                name='idSubCategoria'
                                label="Categoría"
                                variant="outlined"
                                value = {idSubCategoria}
                                onChange = { onChange }
                                onBlur={ () => (idSubCategoria === '' ? setState({field:'errorIdSubCategoria',value:true}) : setState({field:'errorIdSubCategoria',value:false}) ) }
                                error = {errorIdSubCategoria}
                                helperText={errorIdSubCategoria && 'Seleccione la categoría'}
                            >
                                <MenuItem value=''>Seleccione una Categoría</MenuItem>
                                {
                                    categories.length !== 0 &&
                                    categories.map((item)=>
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>

                        {/**stock */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Stock'
                                name='stock'
                                id='stock'
                                required
                                value={stock}
                                onChange = { onChange }
                            />
                        </Grid>

                        

                        {/**Precio */}
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Precio'
                                name='precio'
                                id='precio'
                                required
                                value={precio}
                                onChange = { onChange }
                                onBlur={ () => (precio === '' ? setState({field:'errorPrecio',value:true}) : setState({field:'errorPrecio',value:false}) ) }
                                error = {errorPrecio}
                                helperText={errorPrecio && 'Ingrese el precio del producto'}
                            />
                        </Grid>

                        {/**Precio FF */}
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label='Precio FF'
                                name='precioFF'
                                id='precioFF'
                                required
                                value={precioFF}
                                onChange = { onChange }
                                onBlur={ () => setState({field:'errorPrecioFF',value:precioFF===''?true:false}) }
                                error = {errorPrecioFF}
                                helperText={errorPrecioFF && 'Ingrese el precio del producto'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                onClick={()=>handleAddProduct()}
                            >
                                <Typography>
                                    Agregar producto
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={'Si'}
                onClick={()=>handleClose()}
                secondaryText={'No'}
                secondaryAction={()=>history.push('/products')}
            />
        </Container>
    )
}