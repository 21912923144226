import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";

export async function GetToCrerateBankAccount()
{
    let response = await GET(CompanyRoutes.Finances.BankAccounts.GetToCrerate);
    return response;
}

export async function CreateBankAccount(useFor,paymentMethod,accountType,bank,description,accountNumber)
{
    const params = [
        {name:'use_for_id',value:useFor},
        {name:'payment_method_id',value:paymentMethod},
        {name:'account_type_id',value:accountType},
        {name:'bank_id',value:bank},
        {name:'description',value:description},
        {name:'account_number',value:accountNumber},
    ];

    let response = await POST(CompanyRoutes.Finances.BankAccounts.Add,params);
    return response;
}

export async function GetAllBankAccounts()
{
    const response = await GET(CompanyRoutes.Finances.BankAccounts.GetAll);
    return response;
}


export async function ChangeStatus(id)
{
    let response = await GET(CompanyRoutes.Finances.BankAccounts.ChangeStatus+id);
    return response;
}

export async function Delete(id)
{
    let response = await GET(CompanyRoutes.Finances.BankAccounts.Delete+id);
    return response;
}