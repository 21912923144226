import moment from 'moment';
import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST } from "../ConsumeApiController";

export async function getEntitiesByType(branch_id,type)
{
    let response = await GET(AdminRoutes.Entities.GetAll+`${branch_id}/${type}`);
    return response;
}

export async function createEntity(branch_id,type,name,document,email,phone)
{
    const params = [
        {name:'branch_id',value:branch_id},
        {name:'type',value:type},
        {name:'name',value:name},
        {name:'tax_id',value:document},
        {name:'email',value:email},
        {name:'phone',value:phone},
    ];
    let response = await POST(AdminRoutes.Entities.Create,params);
    return response;
}