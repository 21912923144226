import { useContext, useState } from "react";
import { Box, IconButton, TableCell, Tooltip, Typography } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import UserContext from "../../../navigation/context/userContext";

export default function ProductsDataTable(props)
{
    const {userData} = useContext(UserContext);
    const { type } = useParams();
    const [products,setProducts] = useState(props.products);

    const history = useHistory();
    const redirect = (route) => {
        history.push(route)
    }

    let columns;

    if(userData.user.type === 1)
    columns = [
        {
            name:'name',
            label:<Typography>Nombre</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'category',
            label:<Typography>Categoria</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'stock',
            label:<Typography>Cantidad</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Cantidad</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {products[dataIndex].stock}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'measurement_unit',
            label:<Typography>Unidad</Typography>,
            options:{
                filter: false,
            }
        },
        
        {
            name : 'unit_price',
            label : 'Precio',
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Precio</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {products[dataIndex].unit_price}
                        </Typography>
                    );
                },
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:false,
                headerStyle: {
                    textAlign: 'right' // Align header text to the right
                  }
            }
        },
        {
            name : 'ff_price',
            label : 'Precio FF',
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Precio FF</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {products[dataIndex].ff_price}
                        </Typography>
                    );
                },
                filter:false,
                //filterType:'dropdown',
                //display:false,
                searchable:false,
                headerStyle: {
                    textAlign: 'right' // Align header text to the right
                  }
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Actualizar'>
                                <IconButton onClick={()=>redirect(`/products/update/${products[dataIndex].id}`)}>
                                    <FontAwesomeIcon icon={faPencil}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Detalles'>
                                <IconButton onClick={()=>redirect(`/products/details/${products[dataIndex].id}`)}>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];
    else
    columns = [
        {
            name:'name',
            label:<Typography>Nombre</Typography>,
            options:{
                filter: false
            }
        },
        {
            name:'category',
            label:<Typography>Categoria</Typography>,
            options:{
                filter: true,
            }
        },
        {
            name:'stock',
            label:<Typography>Cantidad</Typography>,
            options:{
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Cantidad</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Typography textAlign={'right'}>
                            {products[dataIndex].stock}
                        </Typography>
                    );
                },
                filter: false,
            }
        },
        {
            name:'measurement_unit',
            label:<Typography>Unidad</Typography>,
            options:{
                filter: false,
            }
        },
        {
            name : '',
            label : <Typography>Acciones</Typography>,
            options : 
            {
                customHeadRender:()=>{
                    return(
                        <TableCell align="right">
                            <Typography>Acciones</Typography>
                        </TableCell>
                    )
                },
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                            <Tooltip title='Detalles'>
                                <IconButton onClick={()=>redirect(`/products/details/${products[dataIndex].id}`)}>
                                    <FontAwesomeIcon icon={faFileInvoice}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    );
                },

                filter:false,
                print:false
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    return(
        <MUIDataTable
            title={<Typography>Listado de Productos</Typography>}
            data={products}
            columns={columns}
            options={options}
        />
    )
}