import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST } from "../ConsumeApiController";


export async function GetProductos(uuid)
{
    let response = await GET(CompanyRoutes.Products.GetProducts+uuid);
    return response;
}

export async function GetForAdd()
{
    let response = await GET(CompanyRoutes.Products.GetForAdd);
    return response;
}

export async function AddProduct(nombre,descripcion,idUnidad,idSubCategoria,precio,estatus,imagen,suscripcion,facturacion='')
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'idUnidad',value:idUnidad},
        {name:'idSubCategoria',value:idSubCategoria},
        {name:'precio',value:precio},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
        {name:'suscripcion',value:suscripcion},
        {name:'facturacion',value:facturacion},
    ];

    let response = await POST(CompanyRoutes.Products.Add,params);
    return response;

}

export async function UpdateProduct(idProducto,nombre,descripcion,idUnidad,idSubCategoria,precio,estatus,imagen,suscripcion,facturacion='')
{
    const params = [
        {name:'idProducto',value:idProducto},
        {name:'nombre',value:nombre},
        {name:'descripcion',value:descripcion},
        {name:'idUnidad',value:idUnidad},
        {name:'idSubCategoria',value:idSubCategoria},
        {name:'precio',value:precio},
        {name:'estatus',value:estatus},
        {name:'imagen',value:imagen},
        {name:'suscripcion',value:suscripcion},
        {name:'facturacion',value:facturacion},
    ];

    let response = await POST(CompanyRoutes.Products.Update,params);
    return response;
}