import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faFileInvoice, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';

//export default class TablaProductos extends Component
export default function OrderDetailsPaymentsDataTable(props)
{
    const {userData,setUserData} = useContext(UserContext)
    const [payments,setPayments] = useState(props.payments);

    const paymentsX = [
        {paymentMethod:'QUD',date:'15/02/2024',amount:6.00,reference:'687436874'}
    ];




    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <TableContainer component={Paper} elevation={3}>
            <Table sx={{minWidth:'100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography fontWeight={'bold'}>Fecha</Typography></TableCell>
                        <TableCell><Typography fontWeight={'bold'}>Forma de Pago</Typography></TableCell>
                        <TableCell><Typography fontWeight={'bold'}>Banco</Typography></TableCell>
                        <TableCell><Typography fontWeight={'bold'}>Cuenta</Typography></TableCell>
                        <TableCell><Typography fontWeight={'bold'}>Referencia</Typography></TableCell>
                        <TableCell align='right'><Typography fontWeight={'bold'}>Monto</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        payments.map(item=>
                            <TableRow>
                                <TableCell>
                                    <Typography >{item.fecha}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography >{item.forma_pago}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography >{item.banco}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography >{item.cuenta}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{item.referencia}</Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Typography>{parseFloat(item.monto).toFixed(2)}</Typography>
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}