import { useEffect, useReducer } from "react";
import { Box, Button, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../../components/breadcrumbs";
import SectionTitle from "../../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { CreateBankAccount, GetToCrerateBankAccount } from "../../../../controllers/company/AccountNumbersController";
import { LoadingButton } from "@mui/lab";
import ModalDialog from "../../../../components/modalDialog";



const initialState = {
    paymentMethod:'',   paymentMethodError: false,
    accountType: '',    accountTypeError: false,
    bank:'',            bankError: false,
    description: '',    descriptionError:false,    
    accountNumber:'',   accountNumberError:false,
    useFor:'',          useForError: false,

    paymentMethods:[],
    accountTypes:[],
    banks:[],

    isLoading : false,
    open : false,
    result : false, message : '', title : '',
    
}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default function AddBankAccount(props)
{
    const [state,setState] = useReducer(reducer,initialState);

    const {
        paymentMethod, paymentMethodError, accountType, accountTypeError, bank, bankError,
        description, descriptionError, accountNumber, accountNumberError,
        useFor, useForError,
        paymentMethods,accountTypes,banks,
        isLoading, result,
        open, message, title, success,
    } = state;

    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetToCreate();
    },[]);

    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }

    const handleGetToCreate = async () => {
        let response = await GetToCrerateBankAccount();
        if(response.success === true)
        {
            setState({field:'paymentMethods',value:response.data.payment_methods});
            setState({field:'accountTypes',value:response.data.account_types});
            setState({field:'banks',value:response.data.banks});
        }
    }

    const formVerification = () => {
        let ban = 0;

        if(useFor === '')
        {
            setState({field:'useForError',value:true});
            ban = 1;
        }

        if(accountType === '')
        {
            setState({field:'accountTypeError',value:true});
            ban = 1;
        }

        if(paymentMethod === '')
        {
            setState({field:'paymentMethodError',value:true});
            ban = 1;
        }

        if(bank === '')
        {
            setState({field:'bankError',value:true});
            ban = 1;
        }

        if(description === '')
        {
            setState({field:'descriptionError',value:true});
            ban = 1;
        }

        if(accountNumber === '')
        {
            setState({field:'accountNumberError',value:true});
            ban = 1;
        }

        return ban;
    }


    const handleCreateAccountNumber = async () => {
        if(formVerification() === 0)
        {
            setState({field:'isLoading',value: true});
            let response = await CreateBankAccount(useFor,paymentMethod,accountType,bank,description,accountNumber);
            if(response.success === true)
            {
                setState({field:'title',value:'Operación Exitosa!'});
                setState({field:'message',value:response.message.message});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:'Operación Fallida!'});
                setState({field:'message',value:response.error});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
            setState({field:'isLoading',value: false});
        }
    }


    return(
        <Container>
            <CustomBreadcrumbs 
                returnTo='/finances/banks'
                crumbs={[{name:'Mis Cuentas Bancarias',link:'/finances/banks'},{name:'Agregar',link:'/finances/banks/add'},]}
            />
            <br/>
            <SectionTitle title='Agregar Cuenta Bancaria'/>
            <br/>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} sm={8} md={8} lg={6} xl={6}>
                    <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="useFor"
                                    name='useFor'
                                    label="Usar para"
                                    variant="outlined"
                                    value = {useFor}
                                    onChange = { onChange }
                                    onBlur={ () => (useFor === '' ? setState({field:'useForError',value:true}) : setState({field:'useForError',value:false}) ) }
                                    error = {useForError}
                                    helperText={useForError && 'Seleccione el uso'}
                                >
                                    <MenuItem value={null}>Seleccione el uso de la cuenta</MenuItem>
                                    <MenuItem key={'pagos'} value='1'>Recibir pago de clientes</MenuItem>
                                    <MenuItem key={'liquidacion'} value='2'>Recibir pagos de QUD</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="accountType"
                                    name='accountType'
                                    label="Tipo de Cuenta"
                                    variant="outlined"
                                    value = {accountType}
                                    onChange = { onChange }
                                    onBlur={ () => (accountType === '' ? setState({field:'accountTypeError',value:true}) : setState({field:'accountTypeError',value:false}) ) }
                                    error = {accountTypeError}
                                    helperText={accountTypeError && 'Seleccione un tipo de cuenta'}
                                >
                                    <MenuItem value={null}>Seleccione una forma de pago</MenuItem>
                                    {
                                        accountTypes &&
                                        accountTypes.map(item=>
                                            <MenuItem key={item.account_type_id} value={item.account_type_id}>{item.name}</MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="paymentMethod"
                                    name='paymentMethod'
                                    label="Forma de Pago"
                                    variant="outlined"
                                    value = {paymentMethod}
                                    onChange = { onChange }
                                    onBlur={ () => (paymentMethod === '' ? setState({field:'paymentMethodError',value:true}) : setState({field:'paymentMethodError',value:false}) ) }
                                    error = {paymentMethodError}
                                    helperText={paymentMethodError && 'Seleccione una forma de pago'}
                                >
                                    <MenuItem value={null}>Seleccione una forma de pago</MenuItem>
                                    {
                                        paymentMethods &&
                                        paymentMethods.map(item=>
                                            <MenuItem key={item.forma_pago_id} value={item.forma_pago_id}>{item.nombre}</MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>

                            
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="bank"
                                    name='bank'
                                    label="Entidad"
                                    variant="outlined"
                                    value = {bank}
                                    onChange = { onChange }
                                    onBlur={ () => (bank === '' ? setState({field:'bankError',value:true}) : setState({field:'bankError',value:false}) ) }
                                    error = {bankError}
                                    helperText={bankError && 'Seleccione un banco'}
                                >
                                    <MenuItem value={null}>Seleccione una forma de pago</MenuItem>
                                    {
                                        banks &&
                                        banks.map(item=>
                                            <MenuItem key={item.bank_id} value={item.bank_id}>{item.name}</MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Nombre'
                                    name='description'
                                    id='description'
                                    required
                                    value={description}
                                    onChange = { onChange }
                                    onBlur={ () => (description === '' ? setState({field:'descriptionError',value:true}) : setState({field:'descriptionError',value:false}) ) }
                                    error = {descriptionError}
                                    helperText={descriptionError && 'Ingrese la descripcion'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={21} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Numero de cuenta'
                                    name='accountNumber'
                                    id='accountNumber'
                                    required
                                    value={accountNumber}
                                    onChange = { onChange }
                                    onBlur={ () => (accountNumber === '' ? setState({field:'accountNumberError',value:true}) : setState({field:'accountNumberError',value:false}) ) }
                                    error = {accountNumberError}
                                    helperText={accountNumberError && 'Ingrese el numero de cuenta'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} >
                                <LoadingButton
                                    style={{padding:15}}
                                    loading={isLoading}
                                    fullWidth
                                    variant="contained"
                                    onClick={()=>handleCreateAccountNumber()}
                                >
                                    <Typography>
                                        Registrar Cuenta Bancaria
                                    </Typography>
                                </LoadingButton>
                            </Grid>
                        </Grid>                        
                    </Box>
                </Grid>
            </Grid>
            <ModalDialog
                open={open}
                title={title}
                msg={message}

                close='Cerrar'
                handleClose={()=>success === true ? history.push('/finances/banks') : setState({field:'open',value:'false'})}
                
                action='Aceptar'
                handleAction={()=>success === true ? history.push('/finances/banks') : setState({field:'open',value:'false'})}
            />
        </Container>
    )
}