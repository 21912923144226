import CompanyRoutes from "../../apiRoutes/CompanyRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function getOrders()
{
    let response = await GET(CompanyRoutes.Orders.GetOrders);
    return response;
}

export async function getOrderDetails(code)
{
    let response = await GET(CompanyRoutes.Orders.GetOrdersDetails+code);
    return response;
}

export async function updateOrderStatus(id_pedido,id_estatus)
{
    const params = [
        {name:'id_pedido',value:id_pedido},
        {name:'id_estatus',value:id_estatus},
    ];

    let response = await POST(CompanyRoutes.Orders.UpdateOrderStatus,params);
    return response;
}