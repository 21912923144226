import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { WebSiteLayout } from '../layouts/Website/WebSiteLayout';
import Home from '../views/website/home';

//AUTH
import SelectUserType from '../views/auth/selectUserType';
import PersonSignUp from '../views/auth/signUp_person';
import SignIn from '../views/auth/signIn';
import ForgotPassword from '../views/auth/forgotPassword';

import { WebSiteLayout2 } from '../layouts/Website/WebsiteLayout2';

import CompanySignUp from '../views/auth/signUp_company';
import EmailVerification from '../views/auth/emailVerification';
import Privacy from '../views/legal/privacy';
import TermsAndConditions from '../views/legal/terms';
import DeleteUser from '../views/legal/deleteUser';

import CompleteProfile from '../views/auth/CompleteProfile';
import { HomeLayout } from '../layouts/Website/home/HomeLayout';





export default function WebSiteNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={HomeLayout} component={Home} />

            {/**AUTH */}
            <AppRoute exact path='/auth/userType' layout={HomeLayout} component={SelectUserType} />
            <AppRoute path='/auth/personSignUp' layout={HomeLayout} component={PersonSignUp} />
            <AppRoute path='/auth/merchantSignUp' layout={HomeLayout} component={CompanySignUp} />
            <AppRoute path='/auth/signIn' layout={HomeLayout} component={SignIn} />
            <AppRoute path='/auth/forgotPassword' layout={WebSiteLayout} component={ForgotPassword} />
            <AppRoute path='/auth/verify/:email/:code' layout={WebSiteLayout} component={EmailVerification} />
            <AppRoute path='/auth/complete/profile/:uuid' layout={WebSiteLayout} component={CompleteProfile} />

            {/**Legal */}
            <AppRoute exact path='/privacy' layout={WebSiteLayout2} component={Privacy} />
            <AppRoute exact path='/termsandconditions' layout={WebSiteLayout2} component={TermsAndConditions} />
            <AppRoute exact path='/deleteUser' layout={WebSiteLayout2} component={DeleteUser} />

            {/**Para las rutas no encontradas */}
            <Route path="*">
                <Redirect  to="/"/>
            </Route>
        </Switch>
    )
}
