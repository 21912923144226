import { forwardRef, useEffect, useReducer, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";

import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { getEntitiesByType } from "../../../controllers/admin/EntitiesController";
import { createCavaRecord } from "../../../controllers/admin/CavaController";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { hasEmptyFields } from "../../../controllers/AuxController";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
    isLoading : false,
    open: false,
    title: '',
    message: '',
    success: false
};

function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}

export default function AddCava(){
    const [date,setDate] = useState(new moment().format());
    const [customers,setCustomers] = useState([]);
    const [details,setDetails] = useState([]);
    const [totalCattle,setTotalCattle] = useState('');

    const [step,setStep] = useState(0);
    const [error,setError] = useState(false);

    const [openModal,setOpenModal] = useState(false);

    const [customer,setCustomer] = useState();

    const [state,setState] = useReducer(reducer,initialState);
    const {
        isLoading,
        open,
        title,
        message,
        success
    } = state


    useEffect(()=>{
        handleGetCustomers()
    },[])

    const handleGetCustomers = async () => {
        const response = await getEntitiesByType(2,'customer');

        if(response.success === true){
            setCustomers(response.data);
        }
    }

    const handleOnTotalCattleChange = (event) => {
        //let total = parseFloat(_total);
        setTotalCattle(event.target.value);
        setDetails(createArrayOfObjects(parseFloat(event.target.value)));
    }

    useEffect(()=>{
        console.log('EL ARRAY ES ===> ',details);
    },[details]);

    function createArrayOfObjects(numberOfObjects) {
        // Validación para asegurarnos de que se pasa un número
        if (typeof numberOfObjects !== 'number' || numberOfObjects <= 0) {
            return []; // Retornamos un array vacío si el número no es válido
        }
      
        // Creamos un array con el tamaño especificado
        return new Array(numberOfObjects*2).fill(null).map((_, index) => ({
            ticket_weight: '',
            input_weight: '',
            cava_number: '',
        }));
    }

    const handleInputChange = (index, field, value) => {
        setDetails(prevDetails => {
            const updatedDetails = [...prevDetails]; // Crear una copia del array de objetos
            updatedDetails[index][field] = value; // Actualizar el campo específico del objeto en el índice dado
            return updatedDetails; // Retornar el nuevo array de objetos actualizado
        });
    };

    const handleDeleteElement = (index) => {
        // Creamos una copia del array para evitar modificar el estado original
        const newArray = [...details];
        // Eliminamos un elemento a partir del índice especificado
        newArray.splice(index, 1);
        // Actualizamos el estado con el nuevo array
        setDetails(newArray);
    };


    const handleNext = () => {
        setStep((prevStep)=>prevStep+1);
    }

    const handleClickOpen = () => {
        setOpenModal(true);
    };
    
    const handleClose = () => {
        setOpenModal(false);
        setTotalCattle('');
        setStep(0);
        setState({field:'open',value:false});
    };

    const handleSubmit = async () => {
        if(hasEmptyFields(details)){
            setError(true);
        }
        else{
            setError(false);
            setState({field:'isLoading',value:true});
            const _date = moment(date).format('YYYY-MM-DD');
            let response = await createCavaRecord(_date,customer.id,totalCattle,details);

            if(response.success === true)
                {
                    setState({field:'title',value:response.message.title});
                    setState({field:'message',value:`${response.message}`});
                    setState({field:'success',value:true});
                    setState({field:'open',value:true});
                }
                else
                {
                    setState({field:'title',value:response.message.title});
                    setState({field:'message',value:response.message});
                    setState({field:'success',value:false});
                    setState({field:'open',value:true});
                }
                setState({field:'isLoading',value:false});
        }
    }

    return(
        <>
            <Button variant='contained' onClick={handleClickOpen}>
                <Typography fontWeight={'bold'}>Agregar</Typography>
            </Button>

            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                maxWidth='md'
                fullWidth
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Registro de Reses"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} mt={1}>

                        {
                            step === 0 &&
                            <>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Fecha"
                                            value={date}
                                            id="date"
                                            name="date"
                                            onChange={(newValue) => setDate(newValue) }
                                            renderInput={(params) => <TextField fullWidth {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        label='Selecciona un cliente'
                                        value={customer}
                                        onChange={(e)=>setCustomer(e.target.value)}
                                    >
                                        {
                                            customers.length !== 0 &&
                                            customers.map(item=>
                                                <MenuItem key={item.id} value={item}>{item.name}</MenuItem>
                                            )
                                        }
                                        
                                    </TextField>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth 
                                        id="outlined-basic" 
                                        label="Ingresa la cantidad de Reses" 
                                        variant="outlined"
                                        value={totalCattle}
                                        onChange={handleOnTotalCattleChange}
                                    />
                                </Grid>
                            </>
                        }

                        {
                            step === 1 &&
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography>{customer.name} - {details.length}</Typography>
                                </Grid>

                                {
                                    
                                    details.map((detail, index) => 
                                        
                                        <>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>{index+1}</Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <TextField
                                                    fullWidth
                                                    label={`Peso Ticket`}
                                                    value={detail.ticket_weight || ""} // Asegura que siempre haya un valor
                                                    onChange={(e) => handleInputChange(index,'ticket_weight', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <TextField
                                                    fullWidth
                                                    label={`Peso Recibido`}
                                                    value={detail.input_weight || ""} // Asegura que siempre haya un valor
                                                    onChange={(e) => handleInputChange(index,'input_weight', e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                <TextField
                                                    fullWidth
                                                    label={`Cava #`}
                                                    value={detail.cava_number}
                                                    onChange={(e) =>
                                                        handleInputChange(index,'cava_number', e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                <IconButton onClick={()=>handleDeleteElement(index)}>
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                </IconButton>
                                            </Grid>
                                        </>
                                    )
                                }
                                
                                
                            </>
                        }
                        {
                            error === true &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography fontWeight={'bold'} textAlign={'center'} color={'error'}>Verifica el formulario e intenta nuevamente. Todos los campos deben estar llenos</Typography>
                            </Grid>
                        }
                        
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    {
                        step === 0 &&
                        <Button variant="contained" onClick={handleNext}>
                            <Typography fontWeight={'bold'}>Aceptar</Typography>
                        </Button>
                    }

                    {
                        step === 1 &&
                        <Button variant="contained" onClick={()=>handleSubmit()}>
                            <Typography fontWeight={'bold'}>Registrar ingreso</Typography>
                        </Button>
                    }
                    
                </DialogActions>
            </Dialog>

            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={success === true ? 'Finalizar' : 'Cerrar'}
                onClick={()=>handleClose()}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />
        </>
    )
}