import { Container, Grid, Typography } from "@mui/material";

export default function TourismCategories()
{
    return(
        <Container>
            <Grid container spacing={3}>
                <Grid item lg={12}>
                    <Typography>CATEGORIAS DE SITIOS TURISTICOS</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}