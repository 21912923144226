import { useContext, useEffect } from "react";
import UserContext from "../../navigation/context/userContext";

import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";

import img_QUDBoy from '../../assets/images/dashboard/qudBoy.jpeg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faTicket, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";




export default function ClientHome()
{
    const { userData } = useContext(UserContext)

    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        window.scrollTo(0,0);
    })

    return(
        <Box pt={3}>
            <Container>
                <Grid container spacing={3}>
                    

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>Panel de control</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                                    onClick={()=>redirect('coupons')}
                                >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h6' component={'p'} fontWeight={'bold'}>Mis Cupones</Typography>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                                    onClick={()=>redirect('reservations')}
                                >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h6' component={'p'} fontWeight={'bold'}>Mis Reservaciones</Typography>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                                    onClick={()=>redirect('orders/1')}
                                >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h6' component={'p'} fontWeight={'bold'}>Mis Boletos</Typography>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Paper variant='outlined' sx={{':hover':{cursor:'pointer',boxShadow:4}}}
                                    onClick={()=>redirect('orders/2')}
                                >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h6' component={'p'} fontWeight={'bold'}>Mis Taxis</Typography>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}