import { useContext, useEffect, useReducer, useState } from "react";
import BranchContext from "../../../navigation/context/branchContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import SectionTitle from "../../../components/sectionTitle";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { getAllOrders } from "../../../controllers/admin/OrdersController";
import OrdersDataTable from "./dataTable";
import { getAllproducts } from "../../../controllers/admin/ProductsController";
import ProductsDataTable from "./dataTable";
import UserContext from "../../../navigation/context/userContext";




export default  function Products()
{
    const {userData} = useContext(UserContext);
    const { selectedBranch, setSelectedBranch } = useContext(BranchContext);
    const [products,setProducts] = useState([]);
    const [statistics,setStatistics] = useState();
    const { type } = useParams();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route);
    }

    useEffect(()=>{
        handleGetProducts();
    },[]);

    const handleGetProducts = async () => {
        let response = await getAllproducts(selectedBranch.id);
        if(response.success === true)
        {
            setProducts(response.data.products); 
        }
    }



    return(
        <Container maxWidth="xl">
            <CustomBreadcrumbs returnTo={`/branch/${selectedBranch.name}`} crumbs={[{name:`productos`}]}/>
            <SectionTitle title={'Productos'}/>
            <Grid container spacing={3}>
                {
                    userData.user.type === 1 &&
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Button fullWidth variant="contained" onClick={()=>redirect(`${history.location.pathname}/create`)}>
                            <Typography variant="h6">
                                Agregar
                            </Typography>
                        </Button>
                    </Grid>
                }
                
                {
                    products.length !== 0 &&
                    <Grid item lg={12} xl={12}>
                        <ProductsDataTable products={products}/>
                    </Grid>
                }
            </Grid>
        </Container>
    )
}