import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';



import { useHistory } from 'react-router-dom';


import { useTheme } from '@mui/material';



import "./homeStyles.css";







export default function Home(props)
{
    const history = useHistory();
    const theme = useTheme();


    const redirect = (route) => {
        history.push(route);
    }




    return(
        <Box sx={{
                overflowX:'hidden',
                minHeight:'85vh',
                width:'100%',
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item>
                    <Button variant='contained' onClick={()=>redirect('/auth/signin')}>
                        <Typography variant='h6'>
                            Inicia Sesion
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}


