import React, { useState } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import { Grid, Paper, CircularProgress } from '@mui/material';

const containerStyle = {
    position: 'relative',  
    width: '100%',
    height: '100%'
}

function MapContainer(props)
{
    const [progress,setProgress] = useState(false);
    const [initialCenter,setInitialCenter] = useState(props.initialCenter);
    const [center,setCenter] = useState(props.center);
    const [markerPosition,setMarkerPosition] = useState(props.coordenadas);

    return(
        <Grid sm={12} md={12} lg={12} xl={12}>
            <Paper style={{marginTop:2,padding:0,borderRadius:10}}>
                <div style={{height:250,width:'100%',borderRadius:10}}>
                  {
                    progress === true
                    ?
                      <CircularProgress />
                    :
                    <Map
                        google={window.google} 
                        zoom={14}
                        style={{borderRadius:10}}
                        initialCenter={props.center}
                        center = {props.center}
                        
                        containerStyle={containerStyle}
                        onClick={props.mapClicked}
                    >
                        {
                            props.coordenadas !== null
                            ?
                            <Marker
                                position={props.coordenadas} 
                            />
                            : null
                        }  
                    </Map>
                  }
                    
                </div>
            </Paper>
        </Grid>
    )
}

const LoadingContainer = (props) => (
    <div>Fancy loading container!</div>
  )

export default GoogleApiWrapper({
    apiKey: 'AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE',
    LoadingContainer: LoadingContainer
})(MapContainer);
  