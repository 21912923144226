import { useContext, useEffect, useState } from "react";
import { Alert, AlertTitle, Avatar, Box, Button, Card, Container, Grid, IconButton, List, ListItem, ListItemAvatar, Divider, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";

import UserContext from "../../navigation/context/userContext";
import QRCode from "react-qr-code";


import publicidadTLS from '../../assets/images/publicidadTLS.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes, faCirclePlus, faCopy, faGifts, faLink, faTags, faTicket, faTools, faUser, faUserCog } from "@fortawesome/free-solid-svg-icons";



import Lottie from "lottie-react";
import buildingAnimation from '../../assets/lottie/building.json';
import { domain } from "../../apiRoutes/apiBase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ShareButton from "../../components/ShareButton";
import ResponseComponent from "../../components/responseComponent";

export default function CompanyHome()
{
    const {userData,setUserData} = useContext(UserContext);

    const [date,setDate] = useState(Date.now())
    const [copySuccess, setCopySuccess] = useState('');

    const [show,setShow] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');

    const history = useHistory();
    const theme = useTheme();

    useEffect(()=>{
        console.log(userData.user)
        setInterval(()=>setDate(new Date()),1000);
    },[]);

    

    const redirect = (route) => {
        history.push(route);
    }

    // your function to copy here

    const handleCopy = (text) => {
        const url = domain+'merchants/'+userData.user.uuid+'/'+text;
        navigator.clipboard.writeText(url);
        
        setSuccess(true);
        setMessage('Enlace copiado exitosamente')
        setShow(true);
        //setTimeout(()=>{setCopySuccess(false)},5000)
        //e.clipboardData.setData("Text", 'HOLA MUNDO!');
    }

    //const date = Date.now();
    const hoy = new Date(date);

 

    return(
        <Container>
            <ResponseComponent
                show={show}
                success={success}
                message={message}
                actionText='Finalizar'
                onClick={()=>setShow(false)}
            />
            <Grid container spacing={3} sx={{mt:5,mb:5}}>
                <Grid item lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faGifts}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h4" fontWeight={'bold'}>Cupones</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Ofrece cupones y descuentos a tus clientes para fidelizarlos y aumentar tus ventas.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{textTransform:'none'}}
                                    onClick={()=>redirect('/coupons/add')}
                                >
                                    <Typography color={'primary.main'}>Emitir Cupon</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.main} size="2x" icon={faTags}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9}>
                                <Typography variant="h4" fontWeight={'bold'}>Catálogo digital</Typography>
                            </Grid>
                            <Grid item lg={12}>
                                <Typography variant='body2'>
                                    Crea un catálogo online de tus productos/servicios para que este al alcance de tus clientes 24/7.
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=>redirect('/categories')}>
                                    <Typography color={'primary.main'}>Categorías</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Button fullWidth size="small" variant="outlined" color="secondary" sx={{textTransform:'none'}} onClick={()=>redirect('/products')}>
                                    <Typography color={'primary.main'}>Productos</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faTicket}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h4" fontWeight={'bold'}>Eventos</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Crea, organiza y promociona tus eventos online o presenciales.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Button
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{textTransform:'none'}}
                                    onClick={()=>redirect('/events')}
                                >
                                    <Typography color={'primary.main'}>Gestionar Eventos</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item lg={4} xl={4}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item lg={3} xl={3}>
                                <Avatar variant='rounded' sx={{width:70,height:70,bgcolor:'#edf2f5'}}>
                                    <FontAwesomeIcon color={theme.palette.link.dark} size="2x" icon={faLink}/>
                                </Avatar>
                            </Grid>
                            <Grid item lg={9} xl={9}>
                                <Typography variant="h4" fontWeight={'bold'}>Enlaces</Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Typography variant='body2'>
                                    Comparte los siguientes enlaces con tus clientes.
                                </Typography>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography variant="h6">
                                        Landing page
                                    </Typography>
                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                        <IconButton edge='end' size="large" onClick={()=> handleCopy('profile')} sx={{mr:1}}>
                                            <FontAwesomeIcon icon={faCopy}/>
                                        </IconButton>
                                        <ShareButton url={`${domain}merchants/${userData.user.uuid}/profile`}/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={12} xl={12}>
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography variant="h6">
                                        Catálogo Digital
                                    </Typography>
                                    <Box sx={{display:'flex',flexDirection:'row'}}>
                                        <IconButton edge='end' size="large" onClick={()=> handleCopy('menu')} sx={{mr:1}}>
                                            <FontAwesomeIcon icon={faCopy}/>
                                        </IconButton>
                                        <ShareButton url={`${domain}merchants/${userData.user.uuid}/menu`}/>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            
        </Container>
    )
}