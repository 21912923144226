import AdminRoutes from '../../apiRoutes/AdminRoutes';
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function createCavaRecord(date,customer,cattle,details)
{
    const response = await POST_JSON(AdminRoutes.Cava.Create,{date,customer,cattle,details});
    return response;
}


export async function getCavaRecords()
{
    const response = await GET(AdminRoutes.Cava.GetAll);
    return response;
}

export async function getCavaDetails(id){
    const response = await GET(AdminRoutes.Cava.GetDetails+id);
    return response;
}

export async function updateDetailByID(id,details)
{
    const response = await POST_JSON(AdminRoutes.Cava.UpdateDetail+id,details);
    return response;
}