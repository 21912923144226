import React, { useContext, useEffect, useReducer, useState } from "react";
import { Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomBreadcrumbs from "../../../components/breadcrumbs";

import { useHistory } from "react-router-dom";
import QUDProgress from "../../../components/progress";
import ResponseComponent from "../../../components/responseComponent";
import BranchContext from "../../../navigation/context/branchContext";
import { create_category, show_category, update_category } from "../../../controllers/admin/CategoriesController";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";






//DECLARAMOS LOS ESTADOS INICIALES
const initialState = {

    nombre : '',          errorNombre : false,
    descripcion : '',     errorDescripcion : false,    
    
    isLoading : false,

    open: false,
    title: '',
    message: '',
    success: false
  }
  
  function reducer( state, { field, value })
  {
    return{
        ...state,
        [field] : value
    }
  }

  


export default function CategoriesUpdate()
{
    const { selectedBranch } = useContext(BranchContext);
    const [state,setState] = useReducer(reducer,initialState);
    const { id } = useParams();

    const {
        nombre, errorNombre, descripcion, errorDescripcion,
        isLoading,
        open,
        title,
        message,
        success

    } = state;

    const history = useHistory();

    useEffect(()=>{
        handleGetCategory();
    },[]);

    const handleGetCategory = async () => {
        let response = await show_category(id);
        if(response.success === true)
        {
            setState({field:'nombre',value:response.data.name});
            setState({field:'descripcion',value:response.data.description});
        }
    }


    const onChange = (e) => {
        setState({field : e.target.name, value : e.target.value});
    }


    const formVerification = () => {
        var ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(descripcion === '')
        {
            setState({field:'errorDescripcion',value:true});
            ban = 1;
        }
        
        return ban;
    }
    
    
    const handleAddCategory = async () => {
        setState({field:'isLoading',value:true});
        if(formVerification() === 0)
        {
            let response = await update_category(id,nombre,descripcion);
            if(response.success === true)
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:`${response.message}.`});
                setState({field:'success',value:true});
                setState({field:'open',value:true});
            }
            else
            {
                setState({field:'title',value:response.message.title});
                setState({field:'message',value:response.message});
                setState({field:'success',value:false});
                setState({field:'open',value:true});
            }
        }
        setState({field:'isLoading',value:false});
    }

    const clear = () => {
        setState({field:'nombre',value:''}); setState({field:'errorNombre',value:false});
        setState({field:'descripcion',value:''}); setState({field:'errorDescripcion',value:false});

        setState({field:'open',value:false});
    }


    const handleClose = () => {
        if(success === true)
        {
            clear();
            history.push('/categories');
        }
        else
            setState({field:'open',value:false})
    }



    return(
        <Container>
            <CustomBreadcrumbs
                returnTo='/categories'
                crumbs={[
                    {name:'Categorías',link:'/categories'},
                    {name:'Actualizar',link:'/categories/update'}
                ]}
            />
            <br/>
            <Typography variant='h4' component='h4' sx={{textAlign:'center'}}>
                Actualizar Categoría
            </Typography>
            <br/>
            <Grid container spacing={3} justifyContent={'center'}>

                {/**Nombre - Descripcion - Unidad de medida - Categoria - Precio - Estatus */}
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container spacing={3}>
                        {/**Nombre */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                label='Nombre'
                                name='nombre'
                                id='nombre'
                                required
                                value={nombre}
                                onChange = { onChange }
                                onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                error = {errorNombre}
                                helperText={errorNombre && 'Ingrese el nombre del producto'}
                            />
                        </Grid>

                        {/**Descripcion */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                label='Descripción'
                                name='descripcion'
                                id='descripcion'
                                required
                                value={descripcion}
                                onChange = { onChange }
                                onBlur={ () => (descripcion === '' ? setState({field:'errorDescripcion',value:true}) : setState({field:'errorDescripcion',value:false}) ) }
                                error = {errorDescripcion}
                                helperText={errorDescripcion && 'Ingrese la descripción del producto'}
                            />
                        </Grid>

                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                fullWidth
                                variant="contained"
                                onClick={()=>handleAddCategory()}
                            >
                                <Typography>
                                    Actualizar Categoría
                                </Typography>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <QUDProgress open={isLoading}/>
            <ResponseComponent
                show={open}
                success={success}
                title={title}
                message={message}
                actionText={success === true ? 'Finalizar' : 'Cerrar'}
                onClick={()=>handleClose()}
                //secondaryText={'No'}
                //secondaryAction={()=>history.push('/categories')}
            />
        </Container>
    )
}